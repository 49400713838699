import React, { useCallback, useEffect, useState } from "react";
import type { DisplayString, IntlMessage } from "../../../types/text";
import type {
  SidebarItem,
  SidebarItemInternal,
  SidebarItemSubmenu,
  SidebarItemSubmenuInternal
} from "./sidebar-types";
import type { SidebarListItemProps } from "./sidebar-list-item";
import { SidebarListItem } from "./sidebar-list-item";
import { SidebarListSubmenu } from "./sidebar-list-submenu";

const LOCAL_STORAGE_MENU_KEY = "sidebarExpanded";

/**
 * This is the main menu that appears on the left side of the application. To
 * add new items to the menu update `allMenuItems`.
 *
 * Which items are available to the user is determined by the response returned
 * by the function provided via the onCanUserViewRoutes prop.
 */
export function Sidebar({
  expand: expandProp = localStorage.getItem(LOCAL_STORAGE_MENU_KEY) === "true",
  onCanUserViewRoutes,
  onGetAnchorElement,
  onItemSelect
}: SidebarProps) {
  const [expand, setExpand] = useState(expandProp);
  const [submenuSelectedHref, setSubmenuSelectedHref] = useState<string | null>(
    null
  );

  useEffect(() => {
    setExpand(expandProp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemClick = useCallback(
    (
      evt: React.SyntheticEvent,
      item: SidebarItemInternal | SidebarItemSubmenuInternal
    ) => {
      // If a submenu item was clicked we don't do anything other than update
      // `submenuSelectedHref`.
      if ("items" in item) {
        evt.preventDefault();
        // If `submenuSelectedHref` equals `item.href` then the user clicked the
        // same submenu again - set a value of `null` which should trigger the
        // submenu to collapse; if the `item.href` doesn't match the current
        // value then set the value of `item.href` which should cause the
        // submenu to expand.
        setSubmenuSelectedHref(current =>
          current !== item.href ? item.href : null
        );
        return;
      }

      // Something other than a submenu was clicked so set `submenuSelectedHref`
      // to null which will cause any expanded submenu to collapse.
      setSubmenuSelectedHref(null);
      onItemSelect && onItemSelect(evt, item);
    },
    [onItemSelect]
  );

  const allowedPaths = onCanUserViewRoutes(
    allMenuItems.map(({ href }) => href)
  );

  const allowedItems = allMenuItems.filter(item =>
    allowedPaths.includes(item.href)
  );

  const handleToggleMenu: React.MouseEventHandler = () => {
    const newExpand = !expand;

    setExpand(newExpand);
    localStorage.setItem(LOCAL_STORAGE_MENU_KEY, String(newExpand));
  };

  return (
    <div className="ui sidebar main-menu">
      <div className="content">
        <ul className={`ui vertical menu${expand ? " expand" : ""}`}>
          {allowedItems.map(item =>
            isSidebarItemSubmenu(item) ? (
              <SidebarListSubmenu
                {...item}
                key={
                  (item.message as unknown as IntlMessage).id ||
                  (item.message as DisplayString).defaultMessage
                }
                onGetAnchorElement={onGetAnchorElement}
                onItemSelect={handleItemClick}
                submenuSelectedHref={submenuSelectedHref ?? undefined}
              />
            ) : (
              <SidebarListItem
                {...item}
                key={
                  (item.message as unknown as IntlMessage).id ||
                  (item.message as DisplayString).defaultMessage
                }
                onGetAnchorElement={onGetAnchorElement}
                onItemSelect={handleItemClick}
                submenuSelectedHref={submenuSelectedHref ?? undefined}
                expanded={expand}
              />
            )
          )}
        </ul>
      </div>
      <div className="actions">
        {/* Mirrors the structure of MainMenuItem so it has the same appearance. */}
        <button className="item" onClick={handleToggleMenu}>
          <div className="item-content">
            <span className="icon">
              <i
                className={`icon circular caret right${
                  expand ? " expand" : ""
                }`}
              ></i>
            </span>
          </div>
        </button>
      </div>
    </div>
  );
}

export interface SidebarProps {
  /** If true the main menu will be shown expanded. Defaults to false. You
   * probably don't want to set this. */
  expand?: boolean;
  /** A function that will be passed all the routes in the main menu and will
   * return a collection of routes that are available to the current user -
   * which will be displayed. */
  onCanUserViewRoutes: (routes: string[]) => string[];
  /**
   * By default each item in the menu will have an `<a>` tag for navigation. To
   * provide a different component (for example a Router `<Link>` component)
   * provide a handler function for onGetAnchorElement which will be invoked for
   * each entry in `allMenuItems`. To use the default `<a>` anchor for an item
   * return `null` from onGetAnchorElement.
   */
  onGetAnchorElement?: SidebarListItemProps["onGetAnchorElement"];
  /** When the user selects a menu item via click or keyboard this event will be
   * raised. If you want to prevent the click from navigating the browser use
   * `preventDefault()`. */
  onItemSelect?: (
    evt: React.SyntheticEvent,
    item: SidebarItem | SidebarItemSubmenu
  ) => void;
}

const allMenuItems: (SidebarItem | SidebarItemSubmenu)[] = [
  {
    message: { id: "MENU_DASHBOARD" },
    href: "/",
    icon: "chart pie"
  },
  {
    message: { id: "MENU_LOS_CURRENT_REQUESTS" },
    href: "/application-phases",
    routes: ["los"],
    icon: "layer group"
  },
  {
    message: { id: "MENU_PPP_CURRENT_REQUESTS" },
    href: "/ppp-applications",
    icon: "circle outline"
  },
  {
    message: { id: "MENU_BORROWER_PORTAL" },
    href: "/borrower-portal-admin",
    icon: "user friends"
  },
  {
    message: { id: "MENU_LOAN_REVIEW_REQUESTS" },
    href: "/loan-review-requests",
    icon: "folder"
  },
  {
    message: { id: "MENU_REPORTS" },
    href: "/reports-indexing-service", // formerly `/reports/search`
    icon: "chart line up"
  },
  {
    message: { id: "MENU_FORMS" },
    href: "/forms",
    icon: "file signature"
  },
  {
    message: { id: "MENU_MONTH_END_FINALIZATION" },
    href: "/month_end_finalization",
    icon: "calendar check"
  },
  {
    message: { id: "MENU_ENTITY_MANAGEMENT" },
    href: "/entity_management",
    routes: ["credit_management", "asset_management", "advanced-search"],
    icon: "briefcase"
  },
  {
    message: { id: "MENU_LOAN_MANAGEMENT" },
    href: "/loan_management/search_loans",
    routes: ["loan_management"],
    icon: "university"
  },
  {
    message: { id: "MENU_TICKLERS_EXCEPTIONS" },
    href: "/docs/ticklers_exceptions",
    icon: "file alternate outline"
  },
  /* "Credit Risk Rating Analysis" is in the design for the sidebar but doesn't actually appear to be needed - credit risk rating is only accessible through other pages? */
  // {
  //   message: { id: "MENU_CREDIT_RISK_RATING" },
  //   href: "/",
  //   icon: "search dollar"
  // },
  /* "Collateral Management" is not part of the April release. */
  // {
  //   message: { id: "MENU_COLLATERAL_MANAGEMENT" },
  //   href: "/",
  //   icon: "wallet"
  // },
  {
    message: { id: "MENU_INSTITUTION_ADMIN" },
    href: "/institution-admin",
    routes: ["institution-admin"],
    icon: "clipboard list"
  },
  {
    message: { id: "MENU_TASK_MANAGEMENT" },
    href: "/task-management",
    routes: ["task-management"],
    icon: "clipboard check"
  },
  {
    message: { id: "MENU_SYSTEM_ADMIN" },
    href: "/system_admin/institutions",
    routes: ["system_admin"],
    icon: "settings"
  }
  // {
  //   message: { defaultMessage: "Hello World" },
  //   href: "/notifications",
  //   icon: "bell"
  // },
  // {
  //   message: { id: "MENU_SETTINGS" },
  //   href: "/settings",
  //   icon: "user cog"
  // }
];

function isSidebarItemSubmenu(
  props: Partial<SidebarItemSubmenu>
): props is SidebarItemSubmenu {
  return Array.isArray(props.items);
}
