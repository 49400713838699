import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  List,
  Loader,
  Grid
} from "semantic-ui-react";
import moment from "moment";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { Messages } from "../../../../services/ApiLib";
import { BorderedContainer } from "../../../../components/CustomUIElements";
import { DateInput } from "../../../../components/CustomFormElements";
import Alert from "../../../../components/Alert";
import { readableNumFormat } from "../../../../services/FormElements";
import Loan from "./components/Loan";
import ScopeDropdown from "./components/ScopeDropdown";
import Constant from "../../../../services/Constants/strings";
import { prettyDate, getUTCDate } from "../../../../services/DateTime";
import { permCheck } from "../../../../services/Auth";
import { LOAN_REVIEW_PERMS } from "../../../../services/Constants/PermissionSets";
import { ConcatName as getEntityName } from "../../../../services/Entities";
import sortArrByDateKey from "../../../../utils/sortArrByDateKey";

function CreditRequest({
  addLoan,
  deleteLoan,
  entityDebt,
  entityDetails,
  handleChange,
  handleChangeNumber,
  interestSchedules,
  loanApp,
  loanAppUuid,
  loanRequests,
  paymentSchedules,
  radioChange,
  readOnly,
  onViewCreditRequest,
  updateLoanApp,
  updateMultiplePropsOnLoanApp
}) {
  const dispatch = useDispatch();
  const [isPPPLoan, setIsPPPLoan] = useState(loanApp.source === "PPP");
  const [loanRequestNumber, setLoanRequestNumber] = useState(
    Object.keys(loanRequests).length
  );
  const [scopeOptions] = useState(() => {
    const defaultScopeOptions = [
      { key: "fn", text: "Full Narrative", value: "Full Narrative" },
      { key: "fns", text: "Full Narrative SBA", value: "Full Narrative SBA" },
      { key: "ln", text: "Limited Narrative", value: "Limited Narrative" },
      {
        key: "lns",
        text: "Limited Narrative SBA",
        value: "Limited Narrative SBA"
      },
      { key: "sc", text: "Small Loan", value: "Small Loan" },
      { key: "do", text: "Docs Only", value: "Docs Only" },
      { key: "pq", text: "Pre-Qualification", value: "Pre-Qualification" },
      {
        key: "fsa",
        text: "Financial Spreads & Analysis Only",
        value: "Financial Spreads Analysis"
      }
    ];
    if (permCheck(LOAN_REVIEW_PERMS)) {
      return [
        ...defaultScopeOptions,
        { key: "lr", text: "Loan Review", value: "Loan Review" }
      ];
    }

    return defaultScopeOptions;
  });

  const [proposedDebt, setProposedDebt] = useState(null);
  const [totalCommitment, setTotalCommitment] = useState(null);

  useEffect(() => {
    if (loanAppUuid) {
      onViewCreditRequest(loanAppUuid);
    }

    if (loanApp) {
      setIsPPPLoan(loanApp.source === "PPP");
    }
  }, [loanApp, loanApp.source, loanAppUuid, onViewCreditRequest]);

  useEffect(() => {
    const loanRequestKeys = Object.keys(loanRequests);
    if (loanRequests) {
      setLoanRequestNumber(loanRequestKeys.length);
    }
    const result = loanRequestKeys.reduce(
      (accumulator, req) => loanRequests[req].original_amount + accumulator,
      0
    );
    if (result >= 0) {
      setProposedDebt(result);
      setTotalCommitment(
        result + entityDebt.direct + entityDebt.indirect || null
      );
      return;
    }
    setProposedDebt(null);
    setTotalCommitment(null);
  }, [entityDebt.direct, entityDebt.indirect, loanRequests]);

  const onError = rsp => {
    dispatch({
      type: "LOS_PHASES_ERROR",
      error: rsp.data
    });
  };
  const onPostMessageSuccess = res => {
    dispatch({ type: "MESSAGING_ADD_MESSAGE", data: res.data });
  };
  const postMessage = async scope => {
    if (
      loanApp.status === Constant.AppSubmitted &&
      loanApp.phase !== Constant.AppWithdrawn
    ) {
      const timeStamp = moment(new Date()).format("MM/DD/YYYY hh:mm:ss a");
      const message = `${loanApp.app_id} (${getEntityName(
        entityDetails
      )}) scope of analysis changed to ${scope} - By USER_NAME - ${timeStamp}`;
      try {
        const messageRsp = await Messages.asyncPost({
          parent_uuid: loanApp.uuid,
          message,
          institution_uuid: loanApp.institution_uuid
        });
        onPostMessageSuccess(messageRsp);
      } catch (err) {
        onError(err);
      }
    }
  };
  function getScopeValue(value) {
    if (value === "PPP") {
      return "PPP";
    }
    if (value === "Loan Review") {
      return "Loan Review";
    }
    return "LOS";
  }

  if (!loanApp) {
    return <Loader>Loading</Loader>;
  }

  const loanRequestsList = loanRequests ? Object.values(loanRequests) : [];
  const sortedLoanRequests = sortArrByDateKey(
    loanRequestsList,
    "created_datetime"
  );

  const renderLoans = sortedLoanRequests.map((loanRequest, index) => {
    const loanRequestUuid = loanRequest?.uuid;

    return (
      <div key={loanRequestUuid}>
        <Loan
          deleteLoan={
            !(isPPPLoan && loanRequestNumber === 1) ? deleteLoan : null
          }
          handleChange={handleChange}
          handleChangeNumber={handleChangeNumber}
          index={index}
          interestSchedule={interestSchedules[loanRequestUuid] || {}}
          loanRequestUuid={loanRequestUuid}
          loanRequest={loanRequest}
          paymentSchedule={paymentSchedules[loanRequestUuid] || {}}
          radioChange={radioChange}
          readOnly={readOnly}
        />
        <Divider clearing hidden />
      </div>
    );
  });

  return (
    <BorderedContainer>
      <Grid>
        <Grid.Column floated="left" width={10}>
          <Header>Credit Request Details</Header>
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          {!readOnly ? (
            <DateInput
              asForm
              width={9}
              label="Estimated close date"
              value={
                loanApp.due_date ? getUTCDate(0, loanApp.due_date, "-") : ""
              }
              name="due_date"
              onChange={(e, data) => data.value && updateLoanApp(data)}
            />
          ) : (
            <Form.Input
              label="Estimated close date"
              value={loanApp.due_date ? prettyDate(loanApp.due_date) : ""}
              name="due_date"
              width={9}
              disabled
            />
          )}
        </Grid.Column>
      </Grid>
      <Form>
        <ListWrapper horizontal relaxed>
          <List.Item>
            <List.Header>Scope of Analysis</List.Header>
            <List.Content>
              <ScopeDropdown
                readOnly={readOnly}
                onChange={(e, data) => {
                  const source = getScopeValue(data.value);
                  postMessage(data.value);
                  updateMultiplePropsOnLoanApp({
                    source,
                    scope: data.value
                  });
                }}
                scopeOptions={scopeOptions}
                value={loanApp.scope}
              />
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Header>Direct Indebtedness</List.Header>
            {entityDebt.direct || entityDebt.direct === 0
              ? readableNumFormat(entityDebt.direct)
              : "Loading"}
          </List.Item>
          <List.Item>
            <List.Header>Indirect Indebtedness</List.Header>
            {entityDebt.indirect || entityDebt.indirect === 0
              ? readableNumFormat(entityDebt.indirect)
              : "Loading"}
          </List.Item>
          <List.Item>
            <List.Header>Proposed Debt</List.Header>
            {readableNumFormat(proposedDebt)}
          </List.Item>
          <List.Item>
            <List.Header>Total Commitment</List.Header>
            {readableNumFormat(totalCommitment)}
          </List.Item>
        </ListWrapper>
        <Header as="h5">Borrower Background</Header>
        {readOnly ? (
          loanApp.app_description || ""
        ) : (
          <Form.TextArea
            name="app_description"
            onChange={(e, data) => updateLoanApp(data)}
            placeholder={Constant.LOR_COMMENTS_PLACEHOLDER}
            value={loanApp.app_description || ""}
          />
        )}
      </Form>
      <Alert message="New Loan Request Added." />
      <Divider clearing hidden />
      <div
        className="mt-4"
        style={{
          position: "relative"
        }}
      >
        {!(isPPPLoan && loanRequestNumber > 0) && (
          <Button
            circular
            disabled={readOnly}
            basic
            icon
            size="big"
            style={{
              position: "absolute",
              top: "10px",
              right: "5px",
              zIndex: 1
            }}
            onClick={() => addLoan(loanApp)}
          >
            <Icon color="green" name="plus" />
          </Button>
        )}
        {renderLoans}
      </div>
    </BorderedContainer>
  );
}

CreditRequest.propTypes = {
  addLoan: PropTypes.func.isRequired,
  deleteLoan: PropTypes.func.isRequired,
  entityDebt: PropTypes.shape({
    direct: PropTypes.number,
    indirect: PropTypes.number
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  interestSchedules: PropTypes.shape({
    accrual_basis: PropTypes.string,
    change_frequency: PropTypes.number,
    change_period: PropTypes.string,
    deleted: PropTypes.bool,
    end_date: PropTypes.string,
    institution_uuid: PropTypes.string,
    interest_rate: PropTypes.number,
    interest_rate_method: PropTypes.string,
    loan_uuid: PropTypes.string,
    margin: PropTypes.number,
    next_change_date: PropTypes.string,
    processing_order: PropTypes.number,
    rate_index: PropTypes.string,
    rate_type: PropTypes.string,
    start_date: PropTypes.string,
    status: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  entityDetails: PropTypes.shape({}),
  loanApp: PropTypes.shape({
    app_description: PropTypes.string,
    scope: PropTypes.string,
    institution_uuid: PropTypes.string,
    uuid: PropTypes.string,
    phase: PropTypes.string,
    status: PropTypes.string,
    app_id: PropTypes.string,
    due_date: PropTypes.string,
    source: PropTypes.string
  }).isRequired,
  loanAppUuid: PropTypes.string.isRequired,
  loanRequests: PropTypes.objectOf(
    PropTypes.shape({
      created_datetime: PropTypes.string,
      adjustmentPeriod: PropTypes.string,
      amortizationTerm: PropTypes.string,
      original_amount: PropTypes.number,
      fee: PropTypes.string,
      loanClass: PropTypes.string,
      loanPurpose: PropTypes.string,
      loanType: PropTypes.string,
      numberOfPayments: PropTypes.string,
      origination_fee: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      paymentPeriod: PropTypes.string,
      paymentType: PropTypes.string,
      rateIndex: PropTypes.string,
      rateType: PropTypes.string,
      requestedRate: PropTypes.string,
      variableRateCeiling: PropTypes.string,
      variableRateFloor: PropTypes.string,
      variableRateMargin: PropTypes.string
    })
  ).isRequired,
  paymentSchedules: PropTypes.shape({
    uuid: PropTypes.string,
    institution_uuid: PropTypes.string,
    loan_uuid: PropTypes.string,
    first_payment_date: PropTypes.string,
    next_payment_date: PropTypes.string,
    next_payment_amount: PropTypes.number,
    payments_remaining: PropTypes.number,
    payment_frequency: PropTypes.number,
    payment_frequency_type: PropTypes.string,
    payment_type: PropTypes.string,
    posting_method: PropTypes.string,
    status: PropTypes.string,
    created_datetime: PropTypes.string,
    processing_order: PropTypes.number,
    total_number_of_payments: PropTypes.number,
    phoenix_import: PropTypes.bool,
    phx_pmt_sched_id_no: PropTypes.number,
    deleted: PropTypes.bool
  }),
  radioChange: PropTypes.func.isRequired,
  updateLoanApp: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  onViewCreditRequest: PropTypes.func.isRequired,
  updateMultiplePropsOnLoanApp: PropTypes.func
};
const ListWrapper = styled(List)`
  /* override semantic-ui-react styles and also the
  !important tag will not be an issue
  since it will be scoped specifically to that component,
  and none other. */
  display: flex !important;
`;
export default CreditRequest;
