import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setTaskSidesheetTab } from "../../../../actions/Tasks";

const Tabs = ({
  className = "",
  showActivityEvents = false,
  showComments = false
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  // Redux
  const TaskMgmt = useSelector(state => state.TaskMgmt);

  const sidesheetTab = TaskMgmt?.sidesheetTab || "details";
  const onClick = tab => {
    dispatch(setTaskSidesheetTab(tab));
  };
  const defaultClassNames =
    "bottom-[-2px] relative border !border-b-0 inline-block px-4 py-2 text-cyan-600 rounded-t-lg";
  const activeClassNames =
    "bg-white active font-bold !border-neutral-300 !text-neutral-700";

  return (
    <ul
      className={`flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-neutral-300 bg-neutral-50 ${className}`}
    >
      <li className="me-2">
        <button
          onClick={() => {
            onClick("taskDetails");
          }}
          className={`${
            sidesheetTab === "taskDetails"
              ? activeClassNames
              : "border-transparent text-neutral-500"
          } ${defaultClassNames}`}
        >
          {formatMessage({ id: "TASK_DETAILS" })}
        </button>
      </li>
      {showActivityEvents && (
        <li className="me-2">
          <button
            onClick={() => {
              onClick("taskEvents");
            }}
            className={`${
              sidesheetTab === "taskEvents"
                ? activeClassNames
                : "border-transparent text-neutral-500"
            } ${defaultClassNames}`}
          >
            {formatMessage({ id: "ACTIVITY_AND_EVENTS" })}
          </button>
        </li>
      )}
      {showComments && (
        <li className="me-2">
          <button
            onClick={() => {
              onClick("taskNotes");
            }}
            className={`${
              sidesheetTab === "taskNotes"
                ? activeClassNames
                : "border-transparent text-neutral-500"
            } ${defaultClassNames}`}
          >
            {formatMessage({ id: "COMMENTS" })}
          </button>
        </li>
      )}
    </ul>
  );
};

export default Tabs;
