import { useEffect } from "react";
import { Label } from "@bafsllc/ui-shared";
import { TASK_EVENT_TYPE_TAG_COLOR } from "../../../../../constants/taskManagement";
import { useDispatch, useSelector } from "react-redux";
import { getTaskEvents } from "../../../../../actions/Tasks";
import EventTypeIcon from "./EventTypeIcon";

const Events = () => {
  const dispatch = useDispatch();
  const TaskMgmt = useSelector(state => state.TaskMgmt);
  // Task
  const task = TaskMgmt?.task || null;
  const taskEvents = TaskMgmt?.taskEvents || null;

  useEffect(() => {
    dispatch(getTaskEvents(task?.uuid));
  }, [dispatch, task?.uuid]);

  return (
    <div className="p-3">
      <ol class="relative border-s border-gray-200 dark:border-gray-700">
        {taskEvents.map(taskEvent => {
          return (
            <li class="mb-10 ms-6">
              <EventTypeIcon eventType={taskEvent?.event_type} />

              <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                <span className="mr-2">{taskEvent?.created_at}</span>
                <Label
                  message={{
                    id: `TASK_EVENT_TYPE_${taskEvent?.event_type}`.toUpperCase()
                  }}
                  color={
                    TASK_EVENT_TYPE_TAG_COLOR[taskEvent.event_type] || "gray"
                  }
                />
              </h3>
              <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                {taskEvent?.created_by}
              </time>
              <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                {taskEvent?.description}
              </p>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Events;
