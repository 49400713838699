import { useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Loan } from "../../../../../../../../services/RTKQuery/ModelTypes/loan";
import {
  DatePicker,
  NumberInputWithLabel,
  TextInput
} from "@bafsllc/ui-shared";

import {
  useLoansQuery,
  useUpdateLoanMutation
} from "../../../../../../../../services/RTKQuery/loans";

function removeNulls(obj: Record<string, any>): Record<string, any> {
  /*
  This works, but is most here for demonstration purposes as there
  should already by one (or likely more than one) abstraction of this.
  It should probably be an RTK abstraction that happens under the hood
  without the consumer's knowledge.
*/
  const newObj: Record<string, any> = {};
  for (const key in obj) {
    if (obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export const PrepareDocsDetailsTab = () => {
  const useParamsRsp = useParams<{ uuid: string; tab: string }>();
  const { uuid } = useParamsRsp;
  const exampleLoanAppUUID = uuid;
  const { formatMessage } = useIntl();
  const [updateLoanDB] = useUpdateLoanMutation();

  const blankLoanData: Partial<Loan> = {};
  const [loanDataState, setLoanDataState] = useState(blankLoanData);

  let { data: loansAPIRsp, isFetching: isFetchingLoans } = useLoansQuery({
    loan_app_uuid: exampleLoanAppUUID
  });

  let loanDataDB: Partial<Loan> = {};
  if (loansAPIRsp && loansAPIRsp.data && loansAPIRsp.data.length <= 1) {
    loanDataDB = loansAPIRsp.data[0];
  } else if (loansAPIRsp && loansAPIRsp.data && loansAPIRsp.data.length > 1) {
    return <div>More than one loan found for this loan app.</div>;
    // throw new Error("More than one loan found for this loan app.");
  }

  if (!isFetchingLoans && loanDataState && !Object.keys(loanDataState).length) {
    setLoanDataState(loanDataDB);
  }

  function onBlur() {
    const deNulledLoanData = removeNulls(loanDataState);
    updateLoanDB(deNulledLoanData);
  }

  function handleInputChange(changedField: string, changedValue: any) {
    const updatedLoanData: Partial<Loan> = {
      ...loanDataDB,
      [changedField]: changedValue
    };
    setLoanDataState(updatedLoanData);
  }

  return (
    <>
      {loanDataState && Object.keys(loanDataState).length ? (
        <div className="grid grid-cols-3 gap-4">
          <div className="col">
            <div className="row pb-2">
              <DatePicker
                required
                labelMessage={{ id: "CURRENT_CONTRACT_DATE" }}
                onDateChange={selectedDate => {
                  handleInputChange("current_contract_date", selectedDate);
                }}
                name="current_contract_date"
                value={
                  loanDataState
                    ? (loanDataState.current_contract_date as any)
                    : ""
                }
                onBlur={() => onBlur()}
              />
            </div>
            <div className="row pb-2">
              <DatePicker
                required
                labelMessage={{ id: "FIRST_ADVANCE_DATE" }}
                onDateChange={selectedDate => {
                  handleInputChange("first_advance_date", selectedDate);
                }}
                name="first_advance_date"
                value={
                  loanDataState ? (loanDataState.first_advance_date as any) : ""
                }
                onBlur={() => onBlur()}
              />
            </div>
          </div>

          <div className="col">
            <div className="row pb-2 mb-1">
              <NumberInputWithLabel
                dollarOption
                labelText={formatMessage({ id: "AMOUNT_REQUESTED" })}
                handleInputChange={value =>
                  handleInputChange("amount_requested", value)
                }
                onBlur={() => onBlur()}
                required
                testId="amount_requested"
                value={(loanDataState.amount_requested as any) || ""}
              />
            </div>
            <div className="row pb-2 mb-1">
              <NumberInputWithLabel
                dollarOption
                labelText={formatMessage({ id: "FIRST_ADVANCE_AMOUNT" })}
                handleInputChange={value =>
                  handleInputChange("first_advance_amount", value)
                }
                onBlur={() => onBlur()}
                testId="first_advance_amount"
                value={`${loanDataState.first_advance_amount}`}
              />
            </div>
          </div>

          <div className="col">
            <div className="row pb-2">
              <DatePicker
                required
                labelMessage={{ id: "MATURITY_DATE" }}
                onDateChange={selectedDate => {
                  handleInputChange("maturity_date", selectedDate);
                }}
                name="maturity_date"
                value={
                  loanDataState ? (loanDataState.maturity_date as any) : ""
                }
                onBlur={() => onBlur()}
              />
            </div>
            <div className="row pb-2">
              <TextInput
                className="p-3"
                handleInputChange={event =>
                  handleInputChange(
                    "institution_account_number",
                    event.target.value
                  )
                }
                labelText={formatMessage({
                  id: "INSTITUTION_ACCOUNT_NUMBER"
                })}
                name="institution_account_number"
                value={loanDataState.institution_account_number || ""}
                required
                testId="institution_account_number"
                onBlur={() => onBlur()}
              />
            </div>
          </div>
        </div>
      ) : (
        <Skeleton className="h-[64px]" />
      )}
    </>
  );
};
