import { useState, useEffect, useCallback } from "react";

import logger from "../../logger";
import Tasks from "../../ApiLib/services/Tasks";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

function useTasks(initialFilters = {}, { limit = 5, page = 1 }) {
  const [tasks, setTasks] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState(initialFilters || {});
  const [defaultFilters] = useState(initialFilters || {});
  // Metadata
  const [lastPage, setLastPage] = useState(false);
  const [count, setCount] = useState({});
  const [pageNumber, setPageNumber] = useState(page);
  const [pageSize, setPageSize] = useState(limit);

  const readTasks = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await Tasks.asyncRead(filters, {
        limit: pageSize,
        page: pageNumber
      });

      if (!response.data) {
        setLastPage(response.metaData ? response.metaData.last_page : false);
        setTasks(response.data ? response.data : []);
        return;
      }

      setCount(response.metaData ? response.metaData.count : 0);
      setPageNumber(response?.metaData?.page_number || page);
      setPageSize(response?.metaData?.page_size || limit);
      setLastPage(response.metaData?.last_page || false);
      setTasks(response?.data);
    } catch (err) {
      onError(err);
    } finally {
      setIsFetching(false);
    }
  }, [filters, limit, page, pageNumber, pageSize]);

  const updateFilters = useCallback(
    value => {
      if (pageNumber !== 1) {
        setPageNumber(1);
      }
      setFilters(value);
    },
    [pageNumber]
  );

  const updatePageNumber = useCallback(value => {
    setPageNumber(value);
  }, []);

  const updatePageSize = useCallback(value => {
    setPageSize(value);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  async function addTask(body) {
    try {
      await Tasks.asyncAdd(body);

      readTasks();
    } catch (err) {
      onError(err);
    }
  }

  async function updateTask(body) {
    try {
      await Tasks.asyncUpdate(body);

      readTasks();
    } catch (err) {
      onError(err);
    }
  }

  async function assignTask(body) {
    try {
      await Tasks.asyncAssign(body);

      readTasks();
    } catch (err) {
      onError(err);
    }
  }

  useEffect(() => {
    // gets data on pagination or entity change
    readTasks();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [readTasks]);

  return {
    tasks,
    isFetching,
    // METADATA
    lastPage,
    pageSize,
    pageNumber,
    count,
    // FILTERS
    updateFilters,
    resetFilters,
    filters,
    // ACTIONS
    updatePageNumber,
    updatePageSize,
    addTask,
    updateTask,
    assignTask,
    readTasks
  };
}

export default useTasks;
