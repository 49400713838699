import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ConcatName } from "../../../../services/Entities";
import Skeleton from "react-loading-skeleton";

function EntityCell({ value: entityUuid = "" }) {
  const TaskMgmt = useSelector(state => state.TaskMgmt);

  const entity = useMemo(() => {
    if (
      Array.isArray(TaskMgmt?.taskEntities) &&
      TaskMgmt?.taskEntities.length > 0
    ) {
      return TaskMgmt.taskEntities.find(te => te.uuid === entityUuid);
    }

    return null;
  }, [TaskMgmt?.taskEntities, entityUuid]);

  if (entityUuid && !entity?.uuid) {
    return <Skeleton />;
  }

  if (entity?.uuid) {
    return <div>{ConcatName(entity)}</div>;
  }

  return null;
}

export default EntityCell;

EntityCell.propTypes = {
  value: PropTypes.string
};
