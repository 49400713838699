import { createId, FormLabel } from "../FormLabel";

export const TextInput = ({
  className = "",
  handleInputChange,
  labelText,
  name,
  onBlur,
  placeholderText,
  required = false,
  testId = "",
  value,
  autoFocus
}: TextInputProps) => {
  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      <FormLabel labelText={labelText} required={required} />

      <input
        id={createId(labelText, "label")}
        name={name}
        onChange={handleInputChange}
        placeholder={placeholderText || labelText}
        type="text"
        data-testid={testId}
        value={value}
        onBlur={onBlur}
        autoFocus={autoFocus}
      />
    </div>
  );
};

export interface TextInputProps {
  className?: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelText: string;
  name: string;
  onBlur: () => void;
  placeholderText?: string;
  required?: boolean;
  autoFocus?: boolean;
  testId?: string;
  value: string;
}
