import { ButtonTW, Search } from "@bafsllc/ui-shared";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Filters from "./Filters";

const ActionsBar = ({ className = "", filters, updateFilters }) => {
  const dispatch = useDispatch();

  const handleSearchFunction = searchValue => {
    if (!searchValue) {
      updateFilters(prev => {
        const newFilters = { ...prev };
        delete newFilters.title;

        return newFilters;
      });
    } else {
      updateFilters(prev => ({
        ...prev,
        title: searchValue
      }));
    }
  };

  const numActiveFilters = useMemo(() => {
    let reconcileValue = 1;

    if (filters?.title) {
      reconcileValue = 2;
    }

    return Object.entries(filters || {}).length - reconcileValue;
  }, [filters]);

  return (
    <div className={`flex items-center justify-between ${className}`}>
      <Search handleSearchFunction={handleSearchFunction} />

      <div className="flex items-center">
        <Filters
          className="mr-3"
          updateFilters={updateFilters}
          filters={filters}
          numActiveFilters={numActiveFilters}
        />
        <ButtonTW
          // disabled={loading}
          onClick={() => {
            dispatch({
              type: "SET_TASK_SIDESHEET",
              sidesheetVisible: true,
              sidesheetEditMode: true
            });
          }}
          size="md"
          variant="primary"
          fontAwesomeIcon={{ icon: faClipboardCheck }}
          aria-label="Submit"
        >
          Create new task
        </ButtonTW>
      </div>
    </div>
  );
};

export default ActionsBar;

// Create New Task
// {
//   "title": "Underwriting",
//   "description": "Underwriting for app id 1234568",
//   "task_type_uuid": "f3acca47-2d69-4d10-b63c-e59b938d0236",
//   "loan_app_uuid": "global00-2d69-4d10-b63c-appid0010398",
//   "source": "BLAST",
//   "requesting_institution_uuid": "institut-ions-0299-GLOB-ALstBranch00",
//   "created_by": "Global Test User"
// }

// Response:

// {
//   "uuid": "3e243a94-46a8-4f7f-bf2e-4f0d30fd8b32",
//   "task_id": 1,
//   "entity_uuid": null,
//   "asset_uuid": null,
//   "loan_app_uuid": "global00-2d69-4d10-b63c-appid0010398",
//   "loan_uuid": null,
//   "requesting_institution_uuid": "institut-ions-0016-IBLS-TestBranch00",
//   "responsible_institution_uuid": "institut-ions-0016-IBLS-TestBranch00",
//   "task_type_uuid": "f3acca47-2d69-4d10-b63c-e59b938d0236",
//   "assigned_department_uuid": "f3acca47-2d69-4d10-b63c-e59b938a7050",
//   "title": "Underwriting",
//   "description": "Underwriting for app id 1234568",
//   "status": "unassigned", // enum('unassigned','assigned','in_progress','completed','on_hold','restarted')
//   "assigned_to": null,
//   "sla_hours": 4,
//   "sla_start_time": "2024-11-06T17:13:33.000Z",
//   "sla_deadline": "2024-11-06T21:13:33.000Z",
//   "sla_status": "within",
//   "is_sla_paused": false,
//   "last_sla_update": "2024-11-06T17:13:33.000Z",
//   "sla_pause_time": null,
//   "sla_total_pause_duration": "0.00",
//   "sla_at_risk_hours": 1,
//   "source": "BLAST",
//   "priority": "standard",
//   "created_by": "Global Test User",
//   "created_at": "2024-11-06T17:13:33.000Z",
//   "updated_at": "2024-11-06T17:13:33.000Z"
// }
