import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

function DueDateCell({ value = "" }) {
  if (value) {
    const slaDeadline = new Date(value);
    return (
      <div>
        <div className="text-center">{format(slaDeadline, "MM/dd/yyyy")}</div>
        <div className="text-base text-center">
          {format(slaDeadline, "h:mm a")}
        </div>
      </div>
    );
  }

  return null;
}

export default DueDateCell;

DueDateCell.propTypes = {
  value: PropTypes.string
};
