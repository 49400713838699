import ApiEndpoints from "../../../../services/ApiEndpoints";
import StatusWidget from "./StatusWidget";
import version from "../../../../version.json";

export const checks = [
  {
    name: "Commotion",
    version: { ...version }
  },
  {
    name: "Loans",
    url: `${ApiEndpoints.baseUri}/loans/status`
  },
  {
    name: "Entities",
    url: `${ApiEndpoints.baseUri}/entities/status`
  },
  {
    name: "CMS v2",
    url: `${ApiEndpoints.baseUri}/cms/v2/status`
  },
  {
    name: "Institutions",
    url: `${ApiEndpoints.baseUri}/institutions/status`
  },
  {
    name: "Messages",
    url: `${ApiEndpoints.baseUri}/messages/status`
  },
  {
    name: "Objects",
    url: `${ApiEndpoints.baseUri}/objects/status`
  },
  {
    name: "Relationships",
    url: `${ApiEndpoints.baseUri}/relationships/status`
  },
  {
    name: "Notifications",
    url: `${ApiEndpoints.baseUri}/notifications/v2/status`
  },
  {
    name: "Task Management",
    url: `${ApiEndpoints.baseUri}/tasks/status`
  },
  {
    name: "Tombstone",
    url: `${ApiEndpoints.baseUri}/auth/v1/status`
  }
];

function StatusPage() {
  return (
    <div>
      <div className="ui segment">
        {checks.map(c => (
          <StatusWidget
            name={c.name}
            statusURL={c.url}
            version={c.version}
            key={c.name}
          />
        ))}
      </div>
    </div>
  );
}

export default StatusPage;
