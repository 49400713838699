import { useMemo } from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import {
  ReactTable,
  reactTableSortCaseInsensitive,
  IndeterminateCheckboxHeader,
  IndeterminateCheckboxCell
} from "@bafsllc/ui-shared";

import ActionsCell from "./Cells/ActionsCell";
import DueDateCell from "./Cells/DueDateCell";
import StatusCell from "./Cells/StatusCell";
import PriorityCell from "./Cells/PriorityCell";
import EntityCell from "./Cells/EntityCell";
import AssignedToCell from "./Cells/AssignedToCell";
// import IdCell from "./Cells/IdCell";
import TitleCell from "./Cells/TitleCell";
import DepartmentCell from "./Cells/DepartmentCell";
import SlaStatusCell from "./Cells/SlaStatusCell";

function TasksTable({
  data,
  isFetching = false,
  showActionsCell = false,
  showActionsMenu = false,
  showSelectCell = false,
  onSelectionChange,
  // ActionCell action handlers
  onClickView,
  onClickEdit
}) {
  const { formatMessage } = useIntl();
  const hiddenColumns = useMemo(() => {
    const cols = ["institution_asset_id"];

    if (!showActionsCell) {
      cols.push("actionsCell");
    }

    if (!showSelectCell) {
      cols.push("select");
    }

    return cols;
  }, [showActionsCell, showSelectCell]);

  const isRowSelectable = row => {
    const asset = row?.original;
    const editableByBorrower = asset?.bpAccessControls?.editable_by_borrower;

    return editableByBorrower;
  };

  const handleHooks = hooks => {
    if (!hiddenColumns.includes("select")) {
      hooks.visibleColumns.push(visibleColumns => [
        {
          id: "selection",
          sticky: "left",
          width: 40,
          Header: props => {
            return (
              <IndeterminateCheckboxHeader
                {...props}
                isRowSelectable={isRowSelectable}
              />
            );
          },
          Cell: props => {
            const disabled =
              !props.cell.row.original?.bpAccessControls?.editable_by_borrower;
            return <IndeterminateCheckboxCell {...props} disabled={disabled} />;
          }
        },
        ...visibleColumns
      ]);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Task name",
        accessor: "title",
        width: 250,
        sortType: reactTableSortCaseInsensitive,
        Cell: TitleCell
      },
      {
        Header: "Entity",
        accessor: "entity_uuid",
        width: 250,
        sortType: reactTableSortCaseInsensitive,
        Cell: EntityCell
      },
      {
        Header: "Department",
        accessor: "department_name",
        width: 250,
        sortType: reactTableSortCaseInsensitive,
        Cell: DepartmentCell
      },
      {
        Header: "Priority",
        accessor: "priority",
        width: 120,
        sortType: reactTableSortCaseInsensitive,
        Cell: PriorityCell
      },
      {
        Header: "Status",
        accessor: "status",
        width: 120,
        sortType: reactTableSortCaseInsensitive,
        Cell: StatusCell
      },
      {
        Header: "Due Date",
        accessor: "sla_deadline",
        width: 110,
        sortType: reactTableSortCaseInsensitive,
        sortUndefined: "last",
        Cell: DueDateCell
      },
      {
        Header: "SLA Status",
        accessor: "sla_status",
        width: 120,
        sortType: reactTableSortCaseInsensitive,
        Cell: SlaStatusCell
      },
      {
        Header: "Assigned to",
        accessor: "assigned_to",
        width: 180,
        disableSortBy: true,
        Cell: AssignedToCell
      },
      {
        Header: "",
        accessor: "actionsCell",
        sticky: "right",
        width: 80,
        disableSortBy: true,
        Cell: ActionsCell,
        showActionsMenu,
        onClickEdit,
        onClickView,
        onSelectionChange
      }
    ],
    [onClickEdit, onClickView, onSelectionChange, showActionsMenu]
  );

  return (
    <ReactTable
      columns={columns}
      data={data}
      isFetching={isFetching}
      emptyDataTitle={formatMessage({ id: "TASKS_NO_DATA_TITLE" })}
      emptyDataSubtitle={formatMessage({ id: "TASKS_NO_DATA_SUBTITLE" })}
      handleHooks={handleHooks}
      hiddenColumns={hiddenColumns}
      onSelectionChange={onSelectionChange}
      sortByAccessorId="sla_deadline"
      sortByAccessorDesc={false}
    />
  );
}

export default TasksTable;

TasksTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  showActionsCell: PropTypes.bool,
  showSelectCell: PropTypes.bool,
  showActionsMenu: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onSelectionChange: PropTypes.func
};
