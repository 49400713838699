/* eslint-env browser */
import { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { debounce } from "lodash";
import { ConcatName } from "../../services/Entities";
import { getInstitutionUuid, isBafs } from "../../services/Auth";
import { forceValue } from "../../services/FormElements";
import { EntityModal } from "../Modals";
import { Entities, Institutions } from "../../services/ApiLib";
import { ButtonTW } from "@bafsllc/ui-shared";
import Skeleton from "react-loading-skeleton";

/** Semantic ui dropdown component causes a warning on console.
 * when selecting the option through enter key.
 * Warning: "Can't call setState (or forceUpdate) on an un-mounted component."
 * This is a semantic ui package issue:
 * https://github.com/Semantic-Org/Semantic-UI-React/issues/3248 */
export class EntitySearchObj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownDisabled: false,
      entities: [],
      entityModalOpen: false,
      entitySearchFiltered: false,
      entitySearchString: "",
      institutions: [],
      loanOfficers: [],
      selectedInstitution: "",
      selectedEntity: ""
    };
    this.handleChangeInstitution = this.handleChangeInstitution.bind(this);
    this.handleSelectEntity = this.handleSelectEntity.bind(this);
    this.processEntityGet = this.processEntityGet.bind(this);
    this.updateSearchValue = this.updateSearchValue.bind(this);
    this.debouncedUpdateSearchValue = debounce(
      this.updateSearchValue.bind(this),
      300
    );
    this.toggleCreateEntityButton = this.toggleCreateEntityButton.bind(this);
    this.onSuccessEntityPost = this.onSuccessEntityPost.bind(this);
  }

  componentDidMount() {
    this.initialLookup();
    const { isEdit } = this.props;
    if (isEdit) {
      this.setState({ dropdownDisabled: true });
    }
  }

  handleSelectEntity(event, { value, options }) {
    const { selectedEntityCallback } = this.props;
    const option =
      value && options ? options.find(o => o.value === value) : null;

    selectedEntityCallback(value, option?.name, option?.type);
  }

  handleChangeInstitution(e, { value }) {
    const { onError, selectedInstitutionCallback, hideEntitySearch } =
      this.props;

    this.setState({ selectedInstitution: value });
    selectedInstitutionCallback(value);

    if (hideEntitySearch) {
      return;
    }

    Entities.read({ institution_uuid: value })
      .then(r => this.processEntityGet(r))
      .catch(err => onError(err));
  }

  onSuccessEntityPost({ data }) {
    const { selectedEntityCallback } = this.props;
    this.setState({ entityModalOpen: false });
    selectedEntityCallback(data.uuid);
  }

  initialLookup() {
    const { onError, overrideInstitution, institutionUuid } = this.props;
    if (isBafs()) {
      if (overrideInstitution) {
        this.setState({ selectedInstitution: institutionUuid });

        Entities.read({ lookup: "", institution_uuid: institutionUuid })
          .then(r => this.processEntityGet(r))
          .catch(err => onError(err));
      } else {
        Institutions.asyncRead({})
          .then(({ data }) => this.setState({ institutions: data }))
          .catch(err => onError(err));
      }
    } else {
      Entities.read({ institution_uuid: getInstitutionUuid() })
        .then(r => this.processEntityGet(r))
        .catch(err => onError(err));
      this.setState({ selectedInstitution: getInstitutionUuid() });
    }
  }

  processEntityGet({ data }) {
    const { selectedEntityCallback, allowedEntities, entityType } = this.props;
    let nextEntities;
    if (allowedEntities) {
      nextEntities = data.filter(entity => allowedEntities[entity.uuid]);
    } else if (entityType === "Individual") {
      nextEntities = data.filter(entity => entity.entity_type === "Individual");
    } else if (entityType === "Business") {
      nextEntities = data.filter(entity => entity.entity_type !== "Individual");
    } else {
      nextEntities = data;
    }
    this.setState({ entities: nextEntities });
    const { asset } = this.props;

    const entitySelected = nextEntities.filter(
      entity => asset && entity && entity.uuid === asset.entity_uuid
    );

    if (!entitySelected || entitySelected.length === 0) {
      return;
    }

    const selectedValue = entitySelected[0].uuid;
    this.setState({ selectedEntity: selectedValue });
    selectedEntityCallback(selectedValue);
  }

  updateSearchValue(e, data) {
    const { props, state } = this;
    const { searchQuery } = data;
    this.setState({
      entitySearchFiltered: !!data,
      entitySearchString: searchQuery
    });
    Entities.read({
      lookup: data.searchQuery,
      institution_uuid: state.selectedInstitution
    })
      .then(r => this.processEntityGet(r))
      .catch(err => props.onError(err));
  }

  toggleCreateEntityButton(entityOverflow) {
    const { entitySearchString } = this.state;
    const { addEntityLabel } = this.props;

    if (entitySearchString) {
      if (entityOverflow) {
        return <div>&nbsp;More Results Available</div>;
      }

      if (!entityOverflow) {
        return (
          <ButtonTW
            className="mt-4"
            variant="primary"
            size="md"
            onClick={() => this.setState({ entityModalOpen: true })}
          >
            {addEntityLabel}
          </ButtonTW>
        );
      }
    }

    return null;
  }

  render() {
    const { state, props } = this;
    const {
      institutions,
      entities,
      entityModalOpen,
      loanOfficers,
      selectedInstitution
    } = state;
    const {
      allowAdd,
      overrideInstitution,
      institutionLabel,
      entityLabel,
      hideEntitySearch,
      isSelectOnNavigation
    } = props;
    const entityFilterOptions = entities
      ? entities.map(entity => ({
          key: entity.uuid,
          text: `${entity.tin} | ${ConcatName(entity)} | ${
            entity.entity_type
          } `,
          name: ConcatName(entity),
          type: entity.entity_type,
          value: entity.uuid
        }))
      : [];

    let moreAvailable = false;
    if (entityFilterOptions.length > 9) {
      entityFilterOptions.length = 9;
      moreAvailable = true;
    }

    const showBafsInstSelector = isBafs() && !overrideInstitution;
    const entityDropdownDisabled =
      showBafsInstSelector && !state.selectedInstitution;

    let institutionOptions = null;
    if (
      showBafsInstSelector &&
      Array.isArray(institutions) &&
      institutions.length > 0
    ) {
      institutionOptions = institutions.map(inst => ({
        key: inst.uuid,
        value: inst.uuid,
        text: `${inst.institution_id} - ${inst.name}`
      }));
    }

    return (
      <div>
        {showBafsInstSelector && (
          <>
            {Array.isArray(institutionOptions) &&
            institutionOptions.length > 0 ? (
              <Form.Dropdown
                data-testid="institutionDropdown"
                fluid
                label={institutionLabel}
                onChange={this.handleChangeInstitution}
                options={institutionOptions}
                placeholder="Select an Institution"
                search
                selection
                value={state.selectedInstitution}
              />
            ) : (
              <div className="field">
                <label>Institution</label>
                <div style={{ marginTop: "-6px" }}>
                  <Skeleton className="w-full" style={{ height: "41.25px" }} />
                </div>
              </div>
            )}
          </>
        )}

        {allowAdd && this.toggleCreateEntityButton(moreAvailable)}
        {!hideEntitySearch && (
          <>
            <Form.Dropdown
              data-testid="entityDropdown"
              className="mt-4"
              data-heap-redact-text
              label={entityLabel}
              disabled={state.dropdownDisabled || entityDropdownDisabled}
              fluid
              noResultsMessage={
                state.entitySearchFiltered ? "No results found" : null
              }
              onChange={this.handleSelectEntity}
              onSearchChange={(e, data) =>
                this.debouncedUpdateSearchValue(e, data)
              }
              options={entityFilterOptions}
              placeholder="Search by entity"
              search
              selection
              selectOnBlur={false}
              selectOnNavigation={isSelectOnNavigation}
              value={
                state.dropdownDisabled
                  ? forceValue(state.selectedEntity)
                  : state.selectedEntityUuid
              }
            />
            {!entityModalOpen ? null : (
              <EntityModal
                isMailingAddress={false}
                selectedInstitution={selectedInstitution}
                loanOfficers={loanOfficers}
                onCloseModal={() => this.setState({ entityModalOpen: false })}
                onSuccess={this.onSuccessEntityPost}
                open
                add
              />
            )}
          </>
        )}
      </div>
    );
  }
}

EntitySearchObj.defaultProps = {
  overrideInstitution: "",
  selectedInstitutionCallback: () => {},
  allowedEntities: null,
  allowAdd: false,
  institutionLabel: "Institution",
  entityLabel: "Entity",
  addEntityLabel: "Create New Entity",
  isSelectOnNavigation: true
};

EntitySearchObj.propTypes = {
  institutionLabel: PropTypes.string,
  institutionUuid: PropTypes.string,
  entityType: PropTypes.string,
  isEdit: PropTypes.bool,
  asset: PropTypes.shape(),
  entityLabel: PropTypes.string,
  addEntityLabel: PropTypes.string,
  overrideInstitution: PropTypes.string,
  onError: PropTypes.func.isRequired,
  selectedEntityCallback: PropTypes.func,
  selectedInstitutionCallback: PropTypes.func,
  allowAdd: PropTypes.bool,
  allowedEntities: PropTypes.objectOf(PropTypes.bool),
  hideEntitySearch: PropTypes.bool,
  isSelectOnNavigation: PropTypes.bool
};

export default EntitySearchObj;
