export const TASK_STATUS_TAG_COLOR = {
  unassigned: "gray",
  assigned: "black",
  in_progress: "blue",
  completed: "green",
  on_hold: "yellow",
  restarted: "red"
};

export const TASK_SLA_STATUS_TAG_COLOR = {
  within: "green",
  at_risk: "yellow",
  breached: "red"
};

export const TASK_PRIORITY_TAG_COLOR = {
  low: "light-blue",
  standard: "blue",
  expedited: "red"
};

export const TASK_EVENT_TYPE_TAG_COLOR = {
  sla_violation: "red",
  note_added: "yellow",
  status_change: "blue",
  assignment_change: "gray",
  priority_change: "black"
};

export const TASK_STATUS_HASH = {
  unassigned: "Unassigned",
  assigned: "Assigned",
  in_progress: "In Progress",
  completed: "Completed",
  on_hold: "On Hold",
  restarted: "Restarted"
};

export const TASK_PRIORITY_HASH = {
  low: "Low",
  standard: "Standard",
  expedited: "Expedited"
};

export const TASK_SLA_STATUS_HASH = {
  within: "Within",
  at_risk: "At Risk",
  breached: "Breached"
};
