import React from "react";
import PropTypes from "prop-types";

export const TitleCell = ({ value = "" }) => {
  if (value) {
    return <div className="">{value}</div>;
  }

  return null;
};

export default TitleCell;

TitleCell.propTypes = {
  value: PropTypes.string
};
