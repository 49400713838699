import React from "react";
import PropTypes from "prop-types";
function DepartmentCell({ value: department_name = "" }) {
  return <div>{department_name || "--"}</div>;
}

export default DepartmentCell;

DepartmentCell.propTypes = {
  value: PropTypes.string
};
