import TypeCheck from "typecheck-extended";
import { permCheck, getUserGroup } from "../..";
import {
  LOAN_REVIEW_PERMS,
  INSTITUTION_ADMIN_PERMS,
  COMMON_DOCS_PERMS,
  PPP_MENU_PERMS
} from "../../../Constants/PermissionSets";

const defaultPageMapping = {
  "/": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/application-phases": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/app_status": {
    Admin: true,
    BAFS: true,
    Institution: false
  },
  "/asset_management": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/auto_decision_request": {
    Admin: true,
    BAFS: true
  },
  "/auto_decision_request/add_entities": {
    Admin: true,
    BAFS: true
  },
  "/borrower-portal-admin": {
    Admin: true,
    BAFS: true,
    Institution: false
  },
  "/credit_management": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/credit_risk_review": {
    Admin: true,
    BAFS: false,
    Institution: false
  },
  "/docs": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/docs/decision_history": {
    Admin: true,
    BAFS: true
  },
  "/docs/ticklers_exceptions": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/entity_management": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/entity_management/advanced-search": {
    Admin: true,
    BAFS: false,
    Institution: false
  },
  "/forms": {
    Admin: true,
    BAFS: true,
    Institution: true,
    perms: COMMON_DOCS_PERMS
  },
  "/task-management": {
    Admin: true,
    BAFS: true,
    Institution: false
  },
  "/institution-admin": {
    Admin: true,
    BAFS: true,
    Institution: true,
    perms: INSTITUTION_ADMIN_PERMS
  },
  "/loan_management": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/loan_management/:uuid/credit-risk-review": {
    Admin: true,
    BAFS: false,
    Institution: false
  },
  "/loan_management/:uuid/details/account-history": {
    Admin: true,
    BAFS: false,
    Institution: false
  },
  "/loan_management/:uuid/details/balance": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/loan_management/:uuid/details/charge-and-fee": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/loan_management/:uuid/details/profitability": {
    Admin: true,
    BAFS: false,
    Institution: false
  },
  "/loan_management/:uuid/payments": {
    Admin: false,
    BAFS: true,
    Institution: false
  },
  "/loan_management/search_loans": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/loan-review-requests": {
    Admin: true,
    BAFS: true,
    Institution: true,
    perms: LOAN_REVIEW_PERMS
  },
  "/login": {
    allUserGroups: true
  },
  "/logout": {
    allUserGroups: true
  },
  "/los": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/month_end_finalization": {
    Admin: true,
    BAFS: true
  },
  "/notifications": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/password_reset/:uuid": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/ppp-applications": {
    Admin: true,
    BAFS: true,
    Institution: true,
    perms: PPP_MENU_PERMS
  },
  "/relationship_review": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/reports-indexing-service": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/settings": {
    Admin: true,
    BAFS: true,
    Institution: true
  },
  "/system_admin/:tab": {
    Admin: true,
    BAFS: true,
    Institution: false
  },
  "/system_admin/institutions": {
    Admin: true,
    BAFS: true,
    Institution: false
  },
  "/welcome": {
    allUserGroups: true
  }
};

export default function userCanViewPage(
  pageName,
  pageMapping = defaultPageMapping
) {
  const possiblePageNames = Object.keys(pageMapping);
  TypeCheck(pageName, "enum", true, possiblePageNames);

  const userGroup = getUserGroup();
  const pageMap = pageMapping[pageName];
  if (pageMap.allUserGroups || pageMap[userGroup]) {
    if (pageMap.perms) {
      // an optional perm array can be used to further restrict page views
      return permCheck(pageMap.perms);
    }

    return true;
  }

  return false;
}
