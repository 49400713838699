/* eslint-env browser */
import { useCallback, useEffect, useMemo } from "react";
import { Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { EntitySearchObj as EntitySearch } from "../../components/EntitySearch";
import DocTitle from "../../components/DocTitle";
import Tabs from "./Tabs";
import RecentSearches from "./RecentSearches";
// import CoreBankingIntegration from "./CoreBankingIntegration";

function onError(rsp) {
  console.error("Error: ", rsp);
}

const EntityManagement = () => {
  const dispatch = useDispatch();
  const EntityManagementReducer = useSelector(
    state => state.EntityManagementReducer
  );
  const selectedEntityUuid = EntityManagementReducer?.selectedEntityUuid;

  const clearSelectEntity = useCallback(() => {
    if (selectedEntityUuid) {
      dispatch({
        type: "ENT_MGMT_CLEAR_ENTITY"
      });
    }
  }, [dispatch, selectedEntityUuid]);

  useEffect(() => {
    clearSelectEntity();
  }, [clearSelectEntity]);

  const saveRecentSearch = (entityUuid, entityName, entityType) => {
    const maxNumSearches = 5;

    let bafsBasicSearches = localStorage.getItem("bafsBasicSearches");
    const newBasicSearches = {
      list: []
    };

    if (bafsBasicSearches) {
      bafsBasicSearches = JSON.parse(bafsBasicSearches);

      if (
        Array.isArray(bafsBasicSearches?.list) &&
        bafsBasicSearches.list.length > 0
      ) {
        newBasicSearches.list = [...bafsBasicSearches.list];
      }
    }

    newBasicSearches.list = [
      {
        entityName,
        entityType,
        entityUuid,
        createdAt: new Date().toISOString()
      },
      ...newBasicSearches.list
    ];

    // Prevent Recent Searches from growing beyond "maxNumSearches"
    newBasicSearches.list = newBasicSearches.list.splice(0, maxNumSearches);

    localStorage.setItem("bafsBasicSearches", JSON.stringify(newBasicSearches));
  };

  const handleSelectEntity = (entityUuid, entityName, entityType) => {
    saveRecentSearch(entityUuid, entityName, entityType);

    dispatch({
      type: "ENT_MANAGEMENT_SELECT_ENTITY",
      value: entityUuid
    });
  };

  const handleChangeInstitution = value => {
    dispatch({
      type: "ENT_MGMT_SELECT_INST",
      value
    });
  };

  const bafsBasicSearches = useMemo(() => {
    let bafsBasicSearches = localStorage.getItem("bafsBasicSearches");

    if (bafsBasicSearches) {
      bafsBasicSearches = JSON.parse(bafsBasicSearches);
    }

    return bafsBasicSearches;
  }, []);

  if (selectedEntityUuid) {
    return <Redirect to={`/credit_management/entity/${selectedEntityUuid}`} />;
  }

  return (
    <DocTitle title="Search - BLAST Portal">
      <h1 className="mb-6">Entity Management</h1>
      <Container className="leftAlign" fluid textAlign="left">
        <Tabs />
        <div className="bg-white !rounded-br-5 !rounded-bl-5 p-3 border border-neutral-300 !border-t-0">
          <EntitySearch
            selectedEntityCallback={handleSelectEntity}
            selectedInstitutionCallback={handleChangeInstitution}
            isSelectOnNavigation={false}
            allowAdd
            onError={onError}
          />
        </div>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <RecentSearches list={bafsBasicSearches?.list} />
          </div>
          {/* <div>
            <CoreBankingIntegration />
          </div> */}
        </div>
      </Container>
    </DocTitle>
  );
};

export default EntityManagement;
