import { ButtonTW, Label, SelectMenu } from "@bafsllc/ui-shared";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { ConcatName } from "../../../../services/Entities";
import {
  TASK_SLA_STATUS_HASH,
  TASK_STATUS_HASH
} from "../../../../constants/taskManagement";
import { useSelector } from "react-redux";

const Filters = ({
  className = "",
  numActiveFilters,
  filters,
  updateFilters
}) => {
  const TaskMgmt = useSelector(state => state.TaskMgmt);
  const taskUsers = TaskMgmt?.taskUsers || null;

  const filtersList = [
    {
      labelText: "Assign To",
      filterParam: "assignedTo",
      apiKey: "assigned_to",
      dataArray: taskUsers,
      dataLabelKey: ConcatName,
      dataValueKey: "uuid"
    },
    {
      labelText: "Status",
      filterParam: "taskStatus",
      apiKey: "status",
      dataMap: TASK_STATUS_HASH
    },
    {
      labelText: "SLA Status",
      filterParam: "slaStatus",
      apiKey: "sla_status",
      dataMap: TASK_SLA_STATUS_HASH
    }
  ];

  return (
    <div className={className}>
      <ButtonTW
        id="dropdownToggleButton"
        data-dropdown-toggle="dropdownToggle"
        size="md"
        variant="secondary"
        {...(numActiveFilters > 0
          ? undefined
          : { fontAwesomeIcon: { icon: faSlidersH } })}
        aria-label="Submit"
      >
        <span className="flex items-center">
          {numActiveFilters > 0 && (
            <span className="mr-2 bg-cyan-100 text-cyan-600 rounded-full px-[6px] py-[2px] text-xs">
              {numActiveFilters}
            </span>
          )}
          <span>Filters</span>
        </span>
      </ButtonTW>
      <div
        id="dropdownToggle"
        className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-72 dark:bg-gray-700 dark:divide-gray-600"
      >
        {filtersList.map((f, i) => {
          const {
            labelText,
            filterParam,
            apiKey,
            dataMap,
            dataArray,
            dataLabelKey,
            dataValueKey
          } = f;

          return (
            <div
              key={`filter-${i}`}
              className={`px-3 pb-3 ${i === 0 ? "pt-3" : "pt-1"}`}
            >
              <SelectMenu
                labelText={labelText}
                inputId={`${apiKey}-field`}
                className="six wide field" // nine || six
                name={apiKey}
                {...(dataMap ? { dataMap } : {})}
                {...(dataArray ? { dataArray } : {})}
                {...(dataLabelKey ? { dataLabelKey } : {})}
                {...(dataValueKey ? { dataValueKey } : {})}
                value={filters?.[apiKey] || ""}
                isClearable
                onChange={args => {
                  if (!args?.value) {
                    updateFilters(prev => {
                      const newFilters = { ...prev };
                      delete newFilters[filterParam];

                      return newFilters;
                    });
                  } else {
                    updateFilters(prev => ({
                      ...prev,
                      [filterParam]: args?.value
                    }));
                  }
                }}
              />
            </div>
          );
        })}

        <div className="p-3">
          <ul
            className="space-y-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownToggleButton"
          >
            <li>
              <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <label className="inline-flex items-center w-full cursor-pointer">
                  <input
                    type="checkbox"
                    value={filters?.includeDone}
                    checked={filters?.includeDone}
                    className="sr-only peer"
                    onChange={e => {
                      const { checked } = e.currentTarget;

                      if (!checked) {
                        updateFilters(prev => {
                          const newFilters = { ...prev };
                          delete newFilters.includeDone;

                          return newFilters;
                        });
                      } else {
                        updateFilters(prev => ({
                          ...prev,
                          includeDone: checked
                        }));
                      }
                    }}
                  />
                  <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-blue-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Include Completed
                  </span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Filters;
