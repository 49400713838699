import { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ConcatName } from "../../../../services/Entities";
import Skeleton from "react-loading-skeleton";

function AssignedToCell({ value: assignedTo }) {
  const TaskMgmt = useSelector(state => state.TaskMgmt);

  const assignedUser = useMemo(() => {
    if (Array.isArray(TaskMgmt?.taskUsers) && TaskMgmt?.taskUsers.length > 0) {
      return TaskMgmt.taskUsers.find(te => te.uuid === assignedTo);
    }

    return null;
  }, [TaskMgmt?.taskUsers, assignedTo]);

  if (assignedTo && !assignedUser?.uuid) {
    return <Skeleton />;
  }

  return (
    <div className="flex justify-center">
      {assignedUser?.uuid && <div>{ConcatName(assignedUser)}</div>}
    </div>
  );
}

export default AssignedToCell;

AssignedToCell.propTypes = {
  value: PropTypes.string,
  toggleAllRowsSelected: PropTypes.func
};
