import { ButtonTW } from "@bafsllc/ui-shared";
import { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

const Textarea = ({
  className = "",
  onSubmit,
  name = "textarea",
  loading = false,
  disabled = false,
  placeholder = "",
  autoFocus = false,
  autoClear = false
}) => {
  const { formatMessage } = useIntl();
  const [searchInputValue, setSearchInputValue] = useState("");

  const onChange = e => {
    const { value } = e.currentTarget;

    setSearchInputValue(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(e);
    if (autoClear) {
      setSearchInputValue("");
    }
  };

  const hasInputValue = useMemo(() => {
    let trimmedValue = (searchInputValue || "").trim();

    return trimmedValue.length > 0;
  }, [searchInputValue]);

  return (
    <div className={`${className} bg-white`}>
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <div className="relative w-full">
            <textarea
              name={name}
              disabled={disabled}
              value={searchInputValue}
              onChange={onChange}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
            <ButtonTW
              className="mt-3"
              disabled={!hasInputValue || loading || disabled}
              loading={loading}
              size="md"
              variant="primary"
              type="submit"
            >
              {formatMessage({ id: "ADD_COMMENT" })}
            </ButtonTW>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Textarea;
