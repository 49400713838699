import { ButtonTW } from "@bafsllc/ui-shared";
import {
  faChevronCircleDown,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

const Pagination = ({
  className = "",
  items = [],
  pageSize,
  pageNumber,
  lastPage,
  count,
  onChangePageNumber,
  onChangePageSize
}) => {
  const pageSizes = [5, 10, 20, 40, 60, 80, 100];

  const numPages = count >= items.length ? Math.ceil(count / pageSize) : 1;

  return (
    <div className={`blast-dropdown flex justify-between ${className}`}>
      <button className="btn btn-md btn-secondary" type="button">
        <span className="flex items-center">
          <span className="mr-2">Page Size: {pageSize}</span>
          <FontAwesomeIcon icon={faChevronCircleDown} />
        </span>
      </button>

      <div className="blast-dropdown-menu hidden z-10 bg-white rounded-lg shadow w-[100px] absolute bottom-[60px] start-8">
        <ul
          className="py-0 text-sm text-gray-700 divide-y divide-gray-100"
          aria-labelledby="paginationDropdownButton"
        >
          {pageSizes.map((num, i) => (
            <li>
              <button
                class={`block px-4 py-2 hover:bg-gray-100 w-full ${
                  num === pageSize && "bg-gray-100"
                } ${i === 0 && "rounded-t-lg"} ${
                  i === pageSizes.length - 1 && "rounded-b-lg"
                }`}
                type="button"
                disabled={num === pageSize}
                onClick={() => {
                  onChangePageSize(num);
                }}
              >
                {num}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex items-center">
        <div className="mr-3 text-sm">
          Page {pageNumber} of {numPages}
        </div>
        <ButtonTW
          size="md"
          variant="primary"
          className="mr-2"
          disabled={pageNumber === 1}
          fontAwesomeIcon={{ icon: faChevronLeft }}
          aria-label="Previous"
          onClick={() => {
            onChangePageNumber(pageNumber - 1);
          }}
        />
        <ButtonTW
          size="md"
          variant="primary"
          disabled={lastPage}
          fontAwesomeIcon={{ icon: faChevronRight }}
          aria-label="Next"
          onClick={() => {
            onChangePageNumber(pageNumber + 1);
          }}
        />
      </div>
    </div>
  );
};

export default Pagination;
