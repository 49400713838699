export const defaultSidesheetTab = "taskDetails";
export const initialState = {
  // SIDESHEET
  sidesheetVisible: false,
  sidesheetEditMode: false,
  sidesheetTab: defaultSidesheetTab,
  // TASK
  task: null,
  // TASK TYPES
  taskTypes: null,
  // TASK ENTITIES
  taskEntities: null,
  // USERS
  users: null,
  // TASK NOTES
  taskNotes: null,
  // TASK EVENTS
  taskEvents: null,
  // LIST
  list: null,
  fetched: false
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    // Sidesheet
    case "SET_TASK_SIDESHEET": {
      return {
        ...state,
        sidesheetVisible: action.sidesheetVisible,
        sidesheetEditMode:
          action.sidesheetEditMode || initialState.sidesheetEditMode,
        sidesheetTab: action.sidesheetTab || defaultSidesheetTab
      };
    }
    case "SET_TASK_SIDESHEET_EDIT_MODE": {
      return {
        ...state,
        sidesheetEditMode: action.sidesheetEditMode
      };
    }
    case "SET_TASK_SIDESHEET_TAB": {
      return {
        ...state,
        sidesheetTab: action.sidesheetTab
      };
    }
    case "RESET_TASK_SIDESHEET":
      return {
        ...state,
        sidesheetVisible: null,
        sidesheetTab: null
      };
    // Task Notes
    case "SET_TASK_NOTES":
      return {
        ...state,
        taskNotes: action?.taskNotes || initialState?.taskNotes
      };
    case "RESET_TASK_NOTES":
      return {
        ...state,
        taskNotes: initialState?.taskNotes
      };
    // Task Events
    case "SET_TASK_EVENTS":
      return {
        ...state,
        taskEvents: action?.taskEvents || initialState?.taskEvents
      };
    case "RESET_TASK_EVENTS":
      return {
        ...state,
        taskEvents: initialState?.taskEvents
      };
    // Task Entities
    case "SET_TASK_ENTITIES":
      return {
        ...state,
        taskEntities: action?.taskEntities || initialState?.taskEntities
      };
    case "RESET_TASK_ENTITIES":
      return {
        ...state,
        taskEntities: initialState?.taskEntities
      };

    // Task Types
    case "SET_TASK_TYPES":
      return {
        ...state,
        taskTypes: action?.taskTypes || initialState?.taskTypes
      };
    case "RESET_TASK_TYPES":
      return {
        ...state,
        taskTypes: initialState?.taskTypes
      };

    // Client Department
    case "SET_TASK_CLIENT_DEPT":
      return {
        ...state,
        clientDepartment:
          action?.clientDepartment || initialState?.clientDepartment
      };
    case "RESET_TASK_CLIENT_DEPT":
      return {
        ...state,
        clientDepartment: initialState?.clientDepartment
      };

    // Client Departments
    case "SET_TASK_CLIENT_DEPTS":
      return {
        ...state,
        clientDepartments:
          action?.clientDepartments || initialState?.clientDepartments
      };
    case "RESET_TASK_CLIENT_DEPTS":
      return {
        ...state,
        clientDepartments: initialState?.clientDepartment
      };

    // Users
    case "SET_TASK_USERS":
      return {
        ...state,
        taskUsers: action?.taskUsers || initialState?.taskUsers
      };
    case "RESET_TASK_USERS":
      return {
        ...state,
        taskUsers: initialState?.taskUsers
      };

    // Task
    case "SET_TASK":
      return {
        ...state,
        task: action?.task || initialState?.task
      };
    case "RESET_TASK":
      return {
        ...state,
        task: initialState?.task,
        taskNotes: initialState?.taskNotes,
        taskEvents: initialState?.taskEvents
      };
    default:
      return state;
  }
}
