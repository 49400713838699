import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useMemo, useState } from "react";
import { createTaskNote, getTaskNotes } from "../../../../../actions/Tasks";
import Textarea from "./Textarea";
import Note from "./Note";

const Notes = ({ className = "" }) => {
  const dispatch = useDispatch();
  // Redux
  const auth = useSelector(state => state.auth);
  const TaskMgmt = useSelector(state => state.TaskMgmt);
  // Auth
  // const createdByUuid = auth?.userUuid || null;
  // Task
  const task = TaskMgmt?.task || null;
  const taskNotes = TaskMgmt?.taskNotes || null;
  // Local State
  const [loading, setLoading] = useState(false);

  const createdBy = useMemo(() => {
    let name = `${auth?.firstName || ""} ${auth?.lastName || ""}`;

    return name.trim();
  }, [auth?.firstName, auth?.lastName]);

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const { note } = e.currentTarget;
    const body = {
      note: note.value,
      task_uuid: task?.uuid,
      created_by: createdBy
      // created_by_uuid: createdByUuid // TODO uncomment after migration
    };

    dispatch(createTaskNote(body, task?.uuid));
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  useEffect(() => {
    dispatch(getTaskNotes(task?.uuid));
  }, [dispatch, task?.uuid]);

  return (
    <div className={className}>
      <div className="flex flex-col flex-auto h-full">
        <div className="flex flex-col flex-shrink-0 p-4">
          {Array.isArray(taskNotes) && taskNotes.length > 0 && (
            <div className="my-3">
              <div className="flex flex-col">
                {taskNotes.map((data, i) => (
                  <Fragment key={`item-${i}`}>
                    <Note
                      date={data.created_at}
                      humanName={data.created_by}
                      text={data.note || null}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          )}

          <Textarea
            autoClear
            autoFocus
            name="note"
            disabled={loading}
            loading={loading}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Notes;
