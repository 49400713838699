import { NotArray, Obj, Option } from "./types";

export const reactSelectOptionsFromHashMap = (
  hash: Obj,
  disabledHash?: Obj
) => {
  if (!hash) {
    return [];
  }

  const data = Object.entries(hash);

  if (!data || (data && Array.isArray(data) && data.length === 0)) {
    return [];
  }

  return Object.entries(hash).map(([key, value]) => {
    return {
      label: value,
      value: key,
      ...(disabledHash && disabledHash[key] ? { isDisabled: true } : {})
    };
  });
};

export const reactSelectOptionsFromArray = (
  data: Obj[],
  labelKey: string | ((item: any) => string),
  valueKey: string,
  disabledHash?: Obj
) => {
  if (
    !labelKey ||
    !data ||
    (data && Array.isArray(data) && data.length === 0)
  ) {
    return [];
  }

  return data.map(item => {
    return {
      label: typeof labelKey === "string" ? item[labelKey] : labelKey(item),
      value: item[valueKey],
      ...(disabledHash && item[valueKey] && disabledHash[item[valueKey]]
        ? { isDisabled: true }
        : {})
    };
  });
};

export const reactSelectDefaultValue = (
  defaultValue: NotArray,
  options: Option[]
): Option | undefined => {
  if (!defaultValue) {
    return undefined;
  }

  if (Array.isArray(defaultValue)) {
    console.error(
      "Type mismatch, Array NOT expected as value of defaultValue."
    );
    return undefined;
  }

  if (!options || (Array.isArray(options) && options.length === 0)) {
    return undefined;
  }

  const defaultOption = options.find(
    (option: Option) => defaultValue === option.value
  );

  return defaultOption || undefined;
};

export const reactSelectDefaultValues = (
  defaultValues: Array<any> = [],
  options: Option[]
): Option[] | undefined => {
  if (!defaultValues) {
    return undefined;
  }

  if (!Array.isArray(defaultValues)) {
    console.error("Type mismatch, expected Array as value of defaultValues.");
    return undefined;
  }

  if (!options || (Array.isArray(options) && options.length === 0)) {
    return undefined;
  }

  const defaultOptions = options.filter((option: Option) =>
    defaultValues.includes(option.value)
  );

  return defaultOptions.length > 0 ? defaultOptions : undefined;
};
