import React from "react";
import PropTypes from "prop-types";
import { Label } from "@bafsllc/ui-shared";
import { TASK_PRIORITY_TAG_COLOR } from "../../../../constants/taskManagement";

function PriorityCell({ value: priority = "" }) {
  const color = TASK_PRIORITY_TAG_COLOR[priority] || "gray";

  if (!priority) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <Label
        message={{
          id: `TASK_PRIORITY_${priority}`.toUpperCase()
        }}
        color={color}
      />
    </div>
  );
}

export default PriorityCell;

PriorityCell.propTypes = {
  value: PropTypes.string
};
