import React from "react";
import PropTypes from "prop-types";
import { Label } from "@bafsllc/ui-shared";
import { TASK_SLA_STATUS_TAG_COLOR } from "../../../../constants/taskManagement";

function SlaStatusCell({ value: sla_status = "" }) {
  const color = TASK_SLA_STATUS_TAG_COLOR[sla_status] || "gray";

  if (!sla_status) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <Label
        message={{
          id: `TASK_SLA_STATUS_${sla_status}`.toUpperCase()
        }}
        color={color}
      />
    </div>
  );
}

export default SlaStatusCell;

SlaStatusCell.propTypes = {
  value: PropTypes.string
};
