import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faExclamationTriangle,
  faUser
} from "@fortawesome/free-solid-svg-icons";

const EventTypeIcon = ({ eventType = "" }) => {
  const classNames =
    "absolute flex items-center justify-center w-8 h-8 rounded-full -start-4";

  if (eventType === "sla_violation") {
    return (
      <span class={`${classNames} !bg-red-100`}>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="!text-red-600"
        />
      </span>
    );
  }

  if (eventType === "note_added") {
    return (
      <span class={`${classNames} !bg-yellow-100`}>
        <FontAwesomeIcon icon={faNoteSticky} className="!text-yellow-600" />
      </span>
    );
  }

  if (eventType === "status_change") {
    return (
      <span class={`${classNames} !bg-cyan-100`}>
        <FontAwesomeIcon icon={faCircle} className="!text-cyan-600" />
      </span>
    );
  }

  if (eventType === "assignment_change") {
    return (
      <span class={`${classNames} !bg-gray-100`}>
        <FontAwesomeIcon icon={faUser} className="!text-gray-600" />
      </span>
    );
  }

  if (eventType === "priority_change") {
    return (
      <span class={`${classNames} !bg-neutral-700`}>
        <FontAwesomeIcon icon={faClock} className="!text-neutral-100" />
      </span>
    );
  }

  return null;
};

export default EventTypeIcon;
