import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

async function asyncAdd(body) {
  const url = FormatUrlV2(ApiEndpoints.tasks, undefined);

  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function asyncUpdate(body) {
  TypeCheck(body.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.task, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncAssign(body) {
  TypeCheck(body.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.taskAssign, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncRead(filters, pagination) {
  const funcName = "====  Tasks.asyncRead(filters)  ====";
  const endpoint = ApiEndpoints.tasks;
  logger.debug(funcName, [filters]);

  let url;
  let params = { ...filters };

  if (pagination) {
    params = { ...params, ...pagination };
  }

  const searchParams = new URLSearchParams(params);

  /**
   *
   * LEGACY
   * Keeping here for when this component is abstracted
   * ConcatQueryParams({ ...params });
   *
   */

  url = FormatUrlV2(endpoint, params);
  url += `?${searchParams.toString()}`;

  return asyncFetchService("GET", url);
}

export default {
  asyncAdd,
  asyncAssign,
  asyncUpdate,
  asyncRead
};
