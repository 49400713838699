import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { func, object, string } from "prop-types";
import { useIntl } from "react-intl";
import { TextInput, SelectMenu, ButtonTW, FormLabel } from "@bafsllc/ui-shared";
import { ConcatName } from "../../../../../services/Entities";
import {
  TASK_PRIORITY_HASH,
  TASK_STATUS_HASH
} from "../../../../../constants/taskManagement";
import {
  getClientDepartment,
  getTask,
  setTaskSidesheetEditMode
} from "../../../../../actions/Tasks";
import "../../index.css";
export function Form({ data, className, addTask, updateTask }) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const auth = useSelector(state => state.auth);
  const TaskMgmt = useSelector(state => state.TaskMgmt);
  // Auth
  const createdByUuid = auth?.userUuid || null;
  const institutionUuid = auth?.institutionUuid || null;
  // Tasks
  const task = TaskMgmt?.task || null;
  const taskTypes = TaskMgmt?.taskTypes || null;
  const taskEntities = TaskMgmt?.taskEntities || null;
  const taskUsers = TaskMgmt?.taskUsers || null;
  const clientDepartments = TaskMgmt?.clientDepartments || null;
  // State
  const [loading, setLoading] = useState(false);
  // Form State
  const [title, setTitle] = useState(data?.title || "");
  const [taskTypeUuid, setTaskTypeUuid] = useState(data?.task_type_uuid || "");
  const [clientDepartmentUuid, setClientDepartmentUuid] = useState(
    data?.assigned_department_uuid || ""
  );
  const [assignedTo, setAssignedTo] = useState(data?.assigned_to || "");
  const [status, setStatus] = useState(data?.status || "");
  const [holdReason, setHoldReason] = useState("");
  const [description, setDescription] = useState(data?.description || "");
  const [accountNumber] = useState(
    data?.loan_uuid || data?.loan_app_uuid || ""
  );
  const [entityUuid, setEntityUuid] = useState(data?.entity_uuid);
  const [priority, setPriority] = useState(data?.priority || "standard");

  const createdBy = useMemo(() => {
    let name = `${auth?.firstName || ""} ${auth?.lastName || ""}`;

    return name.trim();
  }, [auth?.firstName, auth?.lastName]);

  const handleCloseSideSheet = () => {
    dispatch({
      type: "RESET_TASK_SIDESHEET"
    });
  };

  const onCancel = () => {
    if (task?.uuid) {
      dispatch({
        type: "SET_TASK_SIDESHEET_EDIT_MODE",
        sidesheetEditMode: false
      });
      return;
    }

    handleCloseSideSheet();
  };

  const clientDepartment = useMemo(() => {
    if (
      clientDepartmentUuid &&
      Array.isArray(clientDepartments) &&
      clientDepartments.length > 0
    ) {
      return clientDepartments.find(te => te.uuid === clientDepartmentUuid);
    }

    return null;
  }, [clientDepartmentUuid, clientDepartments]);

  const accountNumberLabel = useMemo(() => {
    if (data?.loan_uuid) {
      return "Loan ID";
    }

    // NOTE: May need in future.
    // if (data?.loan_app_uuid) {
    //   return "Loan App ID";
    // }

    return "Loan App ID";
  }, [data?.loan_uuid]);

  const excludeOnHoldOption = useMemo(() => {
    if (
      !task?.sla_status ||
      (task?.sla_status && task?.sla_status !== "within")
    ) {
      return true;
    }

    return false;
  }, [task?.sla_status]);

  useEffect(() => {
    if (taskTypeUuid && Array.isArray(taskTypes) && taskTypes.length > 0) {
      const taskTemplate = taskTypes?.find(tt => tt?.uuid === taskTypeUuid);

      if (taskTemplate?.department_uuid) {
        dispatch(getClientDepartment(taskTemplate?.department_uuid));
        setClientDepartmentUuid(taskTemplate?.department_uuid);
      }
    }
  }, [dispatch, task?.uuid, task?.department_uuid, taskTypes, taskTypeUuid]);

  const onSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const dataToSave = {
      entity_uuid: entityUuid || null,
      // asset_uuid: null,
      requesting_institution_uuid: institutionUuid,
      task_type_uuid: taskTypeUuid,
      title,
      description,
      assigned_to: assignedTo,
      priority
    };

    if (task?.uuid) {
      // UPDATE

      if (status === "on_hold" && task?.status !== "on_hold") {
        dataToSave.holdReason = holdReason;
      }

      await updateTask({
        uuid: task?.uuid,
        status,
        ...dataToSave
      });

      dispatch(getTask(task?.uuid));
      dispatch(setTaskSidesheetEditMode({ sidesheetEditMode: false }));
      setLoading(false);
    } else {
      // CREATE

      await addTask({
        ...dataToSave,
        created_by_uuid: createdByUuid,
        created_by: createdBy,
        source: "BLAST",
        status: assignedTo ? "in_progress" : "unassigned"
      });

      dispatch({
        type: "RESET_TASK_SIDESHEET"
      });
    }
  };

  return (
    <div className={className}>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <TextInput
            handleInputChange={e => {
              const { value } = e.currentTarget;
              setTitle(value);
            }}
            autoFocus
            labelText="Task Name"
            name="title"
            value={title}
            required
            testId="manageTask-title"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-3">
          <div>
            <SelectMenu
              labelText="Task Template"
              inputId="task-template-field"
              className="six wide field" // nine || six
              name="taskTypeUuid"
              dataArray={taskTypes}
              dataLabelKey="task_type_name"
              dataValueKey="uuid"
              value={taskTypeUuid || ""}
              required
              onChange={args => {
                setTaskTypeUuid(args?.value || "");
              }}
            />
          </div>
          <div>
            <SelectMenu
              labelText="Entity"
              inputId="entity-field"
              className="six wide field" // nine || six
              name="entityUuid"
              dataArray={taskEntities}
              dataLabelKey={item => ConcatName(item)}
              dataValueKey="uuid"
              value={entityUuid || ""}
              onChange={args => {
                setEntityUuid(args?.value || "");
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-3">
          <div className={!clientDepartment?.uuid && "opacity-50"}>
            <FormLabel labelText="Client Department" required />

            <FakeInput
              className={`min-h-[42px] ${
                clientDepartment?.uuid && "bg-neutral-50"
              }`}
            >
              {clientDepartment?.name || " "}
            </FakeInput>
          </div>
          <div>
            <FormLabel labelText={accountNumberLabel} />

            <FakeInput className="text-neutral-500 bg-neutral-50">
              {accountNumber || "n/a"}
            </FakeInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-3">
          <div>
            <SelectMenu
              labelText={formatMessage({ id: "ASSIGN_TO" })}
              inputId="assignedTo-field"
              className="six wide field" // nine || six
              name="assignedTo"
              dataArray={taskUsers}
              dataLabelKey={ConcatName}
              dataValueKey="uuid"
              value={assignedTo || ""}
              onChange={args => {
                setAssignedTo(args?.value || "");
              }}
            />
          </div>
          <div>
            <SelectMenu
              labelText={formatMessage({ id: "PRIORITY" })}
              inputId="status-field"
              className="six wide field" // nine || six
              name="priority"
              dataMap={TASK_PRIORITY_HASH}
              value={priority || ""}
              required
              onChange={args => {
                setPriority(args?.value || "");
              }}
            />
          </div>
        </div>

        <div className="mb-3">
          <FormLabel
            labelText={formatMessage({ id: "DESCRIPTION" })}
            required
          />

          <textarea
            type="text"
            name="description"
            value={description}
            required
            onChange={e => {
              const { value } = e.target;
              setDescription(value);
            }}
          />
        </div>

        {task?.uuid && (
          <div className="grid grid-cols-2 gap-6 mb-3">
            <div>
              <SelectMenu
                labelText={formatMessage({ id: "STATUS" })}
                inputId="status-field"
                className="six wide field" // nine || six
                name="status"
                dataMap={TASK_STATUS_HASH}
                disabledOptionsMap={{
                  ...(excludeOnHoldOption ? { on_hold: true } : {})
                }}
                value={status || ""}
                required
                onChange={args => {
                  setStatus(args?.value || "");
                }}
              />
            </div>

            {status === "on_hold" && task.status !== "on_hold" && (
              <div className="mb-3">
                <FormLabel
                  labelText={formatMessage({ id: "REASON_FOR_HOLD" })}
                  required
                />
                <textarea
                  type="text"
                  name="holdReason"
                  value={holdReason}
                  required
                  onChange={e => {
                    const { value } = e.target;
                    setHoldReason(value);
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div className="flex justify-end items-center">
          <ButtonTW
            disabled={loading}
            onClick={onCancel}
            className="mr-3"
            size="md"
            variant="tertiary"
            type="button"
          >
            {formatMessage({ id: "CANCEL" })}
          </ButtonTW>
          <ButtonTW
            disabled={loading}
            loading={loading}
            iconPosition="right"
            size="md"
            variant="primary"
            type="submit"
          >
            {data?.uuid
              ? formatMessage({ id: "SAVE_TASK" })
              : formatMessage({ id: "CREATE_TASK" })}
          </ButtonTW>
        </div>
      </form>
    </div>
  );
}

Form.propTypes = {
  data: object.isRequired,
  className: string,
  addTask: func.isRequired,
  updateTask: func.isRequired
};

export default Form;

const FakeInput = ({ children, className }) => (
  <div
    className={`${className} border border-neutral-300 rounded text-base py-2 px-3 text-nowrap overflow-hidden`}
  >
    {children}
  </div>
);
