import React from "react";
import UserPicture from "../UserPicture";
import "./dot-wave.css";
import "./style.css";
import { prettyDate } from "../../../../../../services/DateTime";

const Note = ({
  agentImage,
  buttonBkgColor = "#FFFFFF",
  className = "mb-3",
  agentName,
  humanName = "You",
  text,
  date = new Date().toISOString(),
  postAsAgent = false
}) => {
  // return text;

  return (
    <div className={`Note ${className}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        {postAsAgent ? (
          <div
            className="MessageAvatar"
            style={{
              background: buttonBkgColor
            }}
          >
            <img
              src={agentImage}
              alt={agentName}
              height={30}
              style={{
                height: "30px"
              }}
            />
          </div>
        ) : (
          <div>
            <UserPicture alt={humanName} />
          </div>
        )}
      </div>

      <div className="ml-3 w-100">
        <div className="flex items-center justify-between mb-1">
          <div
            className="font-bold text-neutral-800" // opacity-75
            style={{ fontSize: "1rem" }}
          >
            {postAsAgent ? agentName : humanName}
          </div>
          <div className="flex items-center">
            <div
              className="text-neutral-500 text-xs"
              style={{
                display: "block"
              }}
            >
              {prettyDate(date, true)}
            </div>
          </div>
        </div>
        <div
          className="shadow p-3 rounded bg-white relative"
          style={{
            wordBreak: "break-word"
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default Note;
