import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonTW, SideSheet } from "@bafsllc/ui-shared";
import Form from "./components/Form";
import Tabs from "./components/Tabs";
import Details from "./components/Details";
import Notes from "./components/Notes";
import Events from "./components/Events";
import { useIntl } from "react-intl";

export default function ManageTaskSideSheet({
  addTask,
  updateTask,
  assignTask
}) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const TaskMgmt = useSelector(state => state.TaskMgmt);

  const task = TaskMgmt?.task?.uuid ? TaskMgmt?.task : null;

  const onEdit = () => {
    dispatch({
      type: "SET_TASK_SIDESHEET_EDIT_MODE",
      sidesheetEditMode: true
    });
  };

  const handleCloseSideSheet = () => {
    dispatch({
      type: "RESET_TASK_SIDESHEET"
    });
    dispatch({
      type: "RESET_TASK"
    });
  };

  const title = useMemo(() => {
    if (task?.uuid) {
      return task?.title;
    }

    return "Create new task";
  }, [task?.title, task?.uuid]);

  return (
    <SideSheet
      isOpen={TaskMgmt?.sidesheetVisible}
      onClose={handleCloseSideSheet}
      size="two-third"
      className="!p-0"
    >
      <div className="notifications-sidebar h-100 w-100 p-4">
        <div className="pb-4 flex items-center justify-between">
          <div className="h3">{title}</div>
        </div>

        <div class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <Tabs
            className="pl-4 pt-4"
            showActivityEvents={!TaskMgmt?.sidesheetEditMode}
            showComments={!TaskMgmt?.sidesheetEditMode}
          />
          <div id="defaultTabContent" className="p-3">
            {TaskMgmt?.sidesheetTab === "taskDetails" && (
              <>
                {TaskMgmt.sidesheetEditMode ? (
                  <Form data={task} addTask={addTask} updateTask={updateTask} />
                ) : (
                  <Details updateTask={updateTask} assignTask={assignTask} />
                )}
              </>
            )}
            {TaskMgmt?.sidesheetTab === "taskEvents" && <Events />}
            {TaskMgmt?.sidesheetTab === "taskNotes" && <Notes />}
          </div>
        </div>
        <div className="mt-3 flex justify-end">
          {TaskMgmt?.sidesheetTab === "taskDetails" &&
            !TaskMgmt.sidesheetEditMode && (
              <ButtonTW size="md" variant="secondary" onClick={onEdit}>
                {formatMessage({ id: "EDIT" })}
              </ButtonTW>
            )}
        </div>
      </div>
    </SideSheet>
  );
}
