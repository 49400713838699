import ApiEndpoints from "../services/ApiEndpoints";
import { Users } from "../services/ApiLib";
import { RemoveNullKeys } from "../services/ApiLib/services/Utilities";
import { asyncFetchService } from "../services/FetchService";
import { FormatUrlV2 } from "../services/FormatUrl";

export const fetchTask = async uuid => {
  if (!uuid) {
    return null;
  }

  const url = FormatUrlV2(ApiEndpoints.task, { uuid });

  const rsp = await asyncFetchService("GET", url);
  return rsp;
};

export const fetchTaskNotes = async taskUuid => {
  if (!taskUuid) {
    return null;
  }

  const url = FormatUrlV2(ApiEndpoints.taskNotes, { uuid: taskUuid });

  const rsp = await asyncFetchService("GET", url);

  return rsp;
};

export const createTaskNotes = async (body, taskUuid) => {
  if (!body) {
    return null;
  }

  const url = FormatUrlV2(ApiEndpoints.taskNotes, { uuid: taskUuid });

  const convertedBody = {
    ...RemoveNullKeys({ ...body })
  };

  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
};

export const fetchTaskEvents = async taskUuid => {
  if (!taskUuid) {
    return null;
  }

  const url = FormatUrlV2(ApiEndpoints.taskEvents, { uuid: taskUuid });

  const rsp = await asyncFetchService("GET", url);
  return rsp;
};

export const fetchTaskEntities = async institutionUuid => {
  if (!institutionUuid) {
    return null;
  }

  const searchParams = new URLSearchParams({
    getBAFS: true,
    institution_uuid: institutionUuid,
    page_size: 100
  });

  const url = FormatUrlV2(
    `${ApiEndpoints.entities}?${searchParams.toString()}`
  );

  const rsp = await asyncFetchService("GET", url);

  return rsp;
};

export const fetchTaskUsers = async institutionUuid => {
  if (!institutionUuid) {
    return null;
  }

  // const searchParams = new URLSearchParams({
  //   getBAFS: true,
  //   institution_uuid: institutionUuid,
  //   page_size: 100
  // });

  try {
    const rsp = await Users.asyncRead({
      institution_uuid: institutionUuid,
      is_active: true
    });
    return rsp;
    // const formattedUsersList = formatLoanOfficerOptions(data);
    // setLoanOfficers(formattedUsersList);
  } catch (error) {
    console.error(error);
  }

  // const url = FormatUrlV2(
  //   `${ApiEndpoints.entities}?${searchParams.toString()}`
  // );

  // const rsp = await asyncFetchService("GET", url);

  // return rsp;
};

export const fetchTaskTypes = async () => {
  const searchParams = new URLSearchParams({
    getBAFS: true
  });

  const url = FormatUrlV2(
    `${ApiEndpoints.taskTypes}?${searchParams.toString()}`,
    null
  );

  const rsp = await asyncFetchService("GET", url);
  return rsp;
};

export const fetchClientDepartments = async institutionUuid => {
  let url = FormatUrlV2(ApiEndpoints.clientDepartments, null);

  if (institutionUuid) {
    url = FormatUrlV2(ApiEndpoints.institutionClientDepartments, {
      institutionUuid
    });
  }

  const rsp = await asyncFetchService("GET", url);
  return rsp;
};

export const fetchClientDepartment = async uuid => {
  if (!uuid) {
    return null;
  }

  const url = FormatUrlV2(ApiEndpoints.clientDepartment, { uuid });

  const rsp = await asyncFetchService("GET", url);
  return rsp;
};

export const getTaskNotes = taskUuid => async dispatch => {
  try {
    const rsp = await fetchTaskNotes(taskUuid);
    const taskNotes = Array.isArray(rsp?.data) ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_NOTES",
      taskNotes
    });

    return taskNotes;
  } catch (error) {
    console.error(error);
  }
};

export const createTaskNote = (body, taskUuid) => async dispatch => {
  try {
    await createTaskNotes(body, taskUuid);

    const rsp = await fetchTaskNotes(taskUuid);
    const taskNotes = Array.isArray(rsp?.data) ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_NOTES",
      taskNotes
    });

    return taskNotes;
  } catch (error) {
    console.error(error);
  }
};

export const getTaskEvents = uuid => async dispatch => {
  try {
    const rsp = await fetchTaskEvents(uuid);
    const taskEvents = Array.isArray(rsp?.data) ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_EVENTS",
      taskEvents
    });

    return taskEvents;
  } catch (error) {
    console.error(error);
  }
};

export const getTask = uuid => async dispatch => {
  try {
    const rsp = await fetchTask(uuid);
    const task = rsp?.data?.uuid ? rsp?.data : null;

    dispatch({
      type: "SET_TASK",
      task
    });

    dispatch(getTaskNotes(uuid));
    dispatch(getTaskEvents(uuid));

    return task;
  } catch (error) {
    console.error(error);
  }
};

export const getClientDepartment = uuid => async dispatch => {
  try {
    // TODO rm hardcoded uuid
    const rsp = await fetchClientDepartment(uuid);

    const clientDepartment = rsp?.data?.uuid ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_CLIENT_DEPT",
      clientDepartment
    });

    return clientDepartment;
  } catch (error) {
    console.error(error);
  }
};

export const getTaskUsers = institutionUuid => async dispatch => {
  try {
    const rsp = await fetchTaskUsers(institutionUuid);
    const taskUsers =
      Array.isArray(rsp?.data) && rsp?.data.length > 0 ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_USERS",
      taskUsers
    });

    return taskUsers;
  } catch (error) {
    console.error(error);
  }
};

export const getClientDepartments = institutionUuid => async dispatch => {
  try {
    const rsp = await fetchClientDepartments(institutionUuid);
    const clientDepartments =
      Array.isArray(rsp?.data) && rsp?.data.length > 0 ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_CLIENT_DEPTS",
      clientDepartments
    });

    return clientDepartments;
  } catch (error) {
    console.error(error);
  }
};

export const getTaskTypes = () => async dispatch => {
  try {
    const rsp = await fetchTaskTypes();
    const taskTypes =
      Array.isArray(rsp?.data) && rsp?.data.length > 0 ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_TYPES",
      taskTypes
    });

    return taskTypes;
  } catch (error) {
    console.error(error);
  }
};

export const getTaskEntities = institutionUuid => async dispatch => {
  if (!institutionUuid) {
    return null;
  }

  try {
    const rsp = await fetchTaskEntities(institutionUuid);
    const taskEntities =
      Array.isArray(rsp?.data) && rsp?.data.length > 0 ? rsp?.data : null;

    dispatch({
      type: "SET_TASK_ENTITIES",
      taskEntities
    });

    return taskEntities;
  } catch (error) {
    console.error(error);
  }
};

export const setTaskSidesheetTab = sidesheetTab => async dispatch => {
  dispatch({
    type: "SET_TASK_SIDESHEET_TAB",
    sidesheetTab
  });
};

export const setTaskSidesheetEditMode = sidesheetEditMode => async dispatch => {
  dispatch({
    type: "SET_TASK_SIDESHEET_EDIT_MODE",
    sidesheetEditMode
  });
};
