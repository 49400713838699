import { ButtonTW, Label } from "@bafsllc/ui-shared";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { ConcatName } from "../../../../../services/Entities";
import { getClientDepartment, getTask } from "../../../../../actions/Tasks";
import {
  TASK_PRIORITY_TAG_COLOR,
  TASK_SLA_STATUS_TAG_COLOR,
  TASK_STATUS_TAG_COLOR
} from "../../../../../constants/taskManagement";
import { FormattedMessage, useIntl } from "react-intl";

const Details = ({ className = "", updateTask, assignTask }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  // Redux
  const TaskMgmt = useSelector(state => state.TaskMgmt);
  const auth = useSelector(state => state.auth);
  // Tasks
  const task = TaskMgmt?.task || null;
  const taskTypes = TaskMgmt?.taskTypes || null;
  const taskEntities = TaskMgmt?.taskEntities || null;
  const taskUsers = TaskMgmt?.taskUsers || null;
  const clientDepartments = TaskMgmt?.clientDepartments || null;
  const taskTypeUuid = task?.task_type_uuid || null;

  const [clientDepartmentUuid, setClientDepartmentUuid] = useState(
    task?.assigned_department_uuid
  );

  const taskTemplate = useMemo(() => {
    if (Array.isArray(taskTypes) && taskTypes.length > 0) {
      return taskTypes?.find(tt => tt?.uuid === taskTypeUuid);
    }

    return null;
  }, [taskTypeUuid, taskTypes]);

  const entity = useMemo(() => {
    if (Array.isArray(taskEntities) && taskEntities.length > 0) {
      return taskEntities.find(te => te.uuid === task?.entity_uuid);
    }

    return null;
  }, [task?.entity_uuid, taskEntities]);

  const clientDepartment = useMemo(() => {
    if (Array.isArray(clientDepartments) && clientDepartments.length > 0) {
      return clientDepartments.find(te => te.uuid === clientDepartmentUuid);
    }

    return null;
  }, [clientDepartmentUuid, clientDepartments]);

  const assignedUser = useMemo(() => {
    if (Array.isArray(taskUsers) && taskUsers.length > 0) {
      return taskUsers.find(te => te.uuid === task?.assigned_to);
    }

    return null;
  }, [taskUsers, task?.assigned_to]);

  const taskIsOverdue = useMemo(() => {
    if (task?.sla_deadline) {
      const nowDate = new Date();
      const slaDate = new Date(task.sla_deadline);

      let diff = nowDate.getTime() - slaDate.getTime();
      if (diff > 0) {
        return true;
      }
    }
    return false;
  }, [task.sla_deadline]);

  const onAssignTo = async () => {
    // NOTE: /assign endpoint not working
    await updateTask({
      // assignTask
      assigned_to: auth?.userUuid,
      status: "in_progress",
      uuid: task?.uuid
    });

    dispatch(getTask(task?.uuid));
  };

  const showAssignToMeButton = useMemo(() => {
    return (
      !assignedUser?.uuid ||
      (task?.assigned_to && task?.assigned_to !== auth.userUuid)
    );
  }, [assignedUser?.uuid, auth.userUuid, task?.assigned_to]);

  useEffect(() => {
    dispatch(getTask(task?.uuid));
  }, [dispatch, task?.uuid]);

  useEffect(() => {
    if (taskTypes && taskTypeUuid) {
      const taskTemplate = taskTypes?.find(tt => tt?.uuid === taskTypeUuid);

      if (taskTemplate?.department_uuid) {
        dispatch(getClientDepartment(taskTemplate?.department_uuid));
        setClientDepartmentUuid(taskTemplate?.department_uuid);
      }
    }
  }, [dispatch, task?.uuid, task?.department_uuid, taskTypes, taskTypeUuid]);

  return (
    <div className={className}>
      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">
          {formatMessage({ id: "TASK_TEMPLATE" })}
        </div>
        <div className="col-span-3 text-left">
          {taskTemplate?.task_type_name}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "DESCRIPTION" })}</div>
        <div className="col-span-3 text-left">{task?.description}</div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "DUE_DATE" })}</div>
        <div className="col-span-3 text-left">
          <div className="flex items-center">
            {task?.sla_deadline ? (
              <>
                <div className="mr-2">
                  {format(new Date(task?.sla_deadline), "MM/dd/yyyy @ hh:mm a")}
                </div>

                {taskIsOverdue && (
                  <Label
                    message={{
                      id: `TASK_SLA_STATUS_${task?.sla_status}`.toUpperCase()
                    }}
                    color={TASK_SLA_STATUS_TAG_COLOR[task.sla_status] || "gray"}
                  />
                )}
              </>
            ) : (
              <div>{formatMessage({ id: "NO_SLA" })}</div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "DEPARTMENT" })}</div>
        <div className="col-span-3 text-left">
          {clientDepartment?.name || "--"}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "ASSIGN_TO" })}</div>
        <div className="col-span-3 text-left">
          <div className="flex items-center">
            {assignedUser?.uuid && (
              <div className="mr-2">{ConcatName(assignedUser)}</div>
            )}

            {showAssignToMeButton && (
              <ButtonTW variant="secondary" size="sm" onClick={onAssignTo}>
                {formatMessage({ id: "ASSIGN_TO_ME" })}
              </ButtonTW>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "ENTITY" })}</div>
        <div className="col-span-3 text-left">
          {entity?.uuid ? (
            <Label
              message={{
                defaultMessage: ConcatName(entity)
              }}
              color="light-blue"
            />
          ) : (
            "--"
          )}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "STATUS" })}</div>
        <div className="col-span-3 text-left">
          {task?.status ? (
            <Label
              message={{
                id: `TASK_STATUS_${task?.status}`.toUpperCase()
              }}
              color={TASK_STATUS_TAG_COLOR[task.status] || "gray"}
            />
          ) : (
            "--"
          )}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-5">
        <div className="col-span-1">{formatMessage({ id: "PRIORITY" })}</div>
        <div className="col-span-3 text-left">
          {task?.priority ? (
            <Label
              message={{
                id: `TASK_PRIORITY_${task?.priority}`.toUpperCase()
              }}
              color={TASK_PRIORITY_TAG_COLOR[task.priority] || "gray"}
            />
          ) : (
            "--"
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
