import { useMemo, useState } from "react";
import styled from "styled-components";

export const AvatarContainer = styled.div`
  border: 1px solid #2c3a47;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: ${props => props.height}px;
  width: ${props => props.width}px;

  img {
    height: ${props => props.height}px;
    width: ${props => props.width}px;
  }
`;

const Avatar = styled.div`
  border-radius: 100%;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
  overflow: hidden;
`;

const getInitials = name => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

const UserPicture = ({ height = "40", width = "40", src, alt }) => {
  const [imgSrc] = useState(src);
  const [imgAlt] = useState(alt || "");

  if (!imgSrc) {
    return (
      <Avatar className="flex items-center content-center border bg-cyan-100">
        <div className="w-full text-center">{getInitials(alt)}</div>
      </Avatar>
    );
  }

  return (
    <AvatarContainer height={height} width={width}>
      <img src={imgSrc} className="w-100" alt={imgAlt} />
    </AvatarContainer>
  );
};

// eslint-disable-next-line import/no-default-export
export default UserPicture;
