import countriesAndCodes from "../CountriesAndCodes";

const createOption = ({ code, name }) => ({
  key: code,
  text: name,
  value: name
});

const createLoanOfficerOption = ({
  first_name: firstName,
  last_name: lastName,
  uuid
}) => ({
  key: uuid,
  text: `${firstName} ${lastName}`,
  value: uuid
});

export const countryOptions = () => {
  const options = [];
  for (let i = 0; i < countriesAndCodes.length; i += 1) {
    options.push(createOption(countriesAndCodes[i]));
  }
  return options;
};

export const loanOfficerOptions = loanOfficers => {
  const options = [];
  for (let i = 0; i < loanOfficers.length; i += 1) {
    options.push(createLoanOfficerOption(loanOfficers[i]));
  }
  return options;
};

export const genderOptions = [
  { key: 0, text: "M", value: "M" },
  { key: 1, text: "F", value: "F" }
];

export const individualTypes = [
  { key: "Individual", text: "Individual", value: "Individual" }
];

export const businessTypes = [
  { key: "Corporation", text: "Corporation", value: "Corporation" },
  {
    key: "General Partnership",
    text: " General Partnership",
    value: "General Partnership"
  },
  { key: "Government", text: "Government", value: "Government" },
  {
    key: "Limited Liability Company",
    text: "Limited Liability Company",
    value: "Limited Liability Company"
  },
  {
    key: "Limited Liability Partnership",
    text: "Limited Liability Partnership",
    value: "Limited Liability Partnership"
  },
  {
    key: "Limited Partnership",
    text: "Limited Partnership",
    value: "Limited Partnership"
  },
  { key: "Non-Profit", text: "Non-Profit", value: "Non-Profit" },
  {
    key: "Professional Association",
    text: "Professional Association",
    value: "Professional Association"
  },
  {
    key: "Single Member LLC",
    text: "Single Member LLC",
    value: "Single Member LLC"
  },
  {
    key: "Sole Proprietership",
    text: "Sole Proprietorship",
    value: "Sole Proprietorship"
  },
  { key: "Trust", text: "Trust", value: "Trust" }
];

export const taxReportingForms = [
  { key: 1040, text: "1040 - Individual Income Tax Return", value: "1040" },
  { key: 1041, text: "1041 - Trust/Estate Income Tax Return", value: "1041" },
  { key: 1065, text: "1065 - Partnership Income Tax Return", value: "1065" },
  { key: 1120, text: "1120 - Corporate Income Tax Return", value: "1120" },
  { key: 0, text: "Other", value: "Other" }
];

export const taxReportFormIndivid = [
  { key: 1040, text: "1040", value: "1040" }
];

export const loanReviewTypes = [
  { key: 0, text: "Relationship Review", value: "Relationship Review" },
  { key: 1, text: "Credit Risk Review", value: "Credit Risk Review" },
  { key: 2, text: "Other", value: "Other" },
  { key: "NA", text: "NA", value: "NA" }
];

export const educationOptions = [
  { key: 0, text: "", value: null },
  { key: 1, text: "Some High School", value: "Some High School" },
  { key: 2, text: "High School Diploma", value: "High School Diploma" },
  { key: 3, text: "Some College", value: "Some College" },
  { key: 4, text: "Associates Degree", value: "Associates Degree" },
  { key: 5, text: "Bachelors Degree", value: "Bachelors Degree" },
  { key: 6, text: "Masters Degree", value: "Masters Degree" },
  { key: 7, text: "Professional Degree", value: "Professional Degree" },
  { key: 8, text: "Doctorate Degree", value: "Doctorate Degree" }
];

export const employmentOptions = [
  { key: 0, text: "Not Employed", value: "Not Employed" },
  { key: 1, text: "Full Time", value: "Full Time" },
  { key: 2, text: "Part Time", value: "Part Time" },
  { key: 3, text: "Self Employed", value: "Self Employed" },
  { key: 4, text: "Other", value: "Other" }
];

export const individualOperatingStatus = [
  { key: 0, text: "Solvent", value: "Solvent" },
  { key: 1, text: "Chapter 7 Bankruptcy", value: "Chapter 7 Bankruptcy" },
  { key: 2, text: "Chapter 13 Bankruptcy", value: "Chapter 13 Bankruptcy" }
];

export const titleOptions = [
  { key: 0, text: "", value: null },
  { key: 1, text: "Mr.", value: "Mr." },
  { key: 2, text: "Mrs.", value: "Mrs." },
  { key: 3, text: "Ms.", value: "Ms." },
  { key: 4, text: "Dr.", value: "Dr." },
  { key: 5, text: "Prof.", value: "Prof." },
  { key: 6, text: "Rev.", value: "Rev." },
  { key: 7, text: "Hon.", value: "Hon." }
];

export const jobTitleOptions = [
  { key: 0, text: "Civil Servants", value: "Civil Servants" },
  {
    key: 1,
    text: "Clerical or Office Workers",
    value: "Clerical or Office Workers"
  },
  { key: 2, text: "Computer Professionals", value: "Computer Professionals" },
  {
    key: 3,
    text: "Executive/Administrators",
    value: "Executive/Administrators"
  },
  { key: 4, text: "Farming/Agriculture", value: "Farming/Agriculture" },
  { key: 5, text: "Financial Services", value: "Financial Services" },
  { key: 6, text: "Middle Management", value: "Middle Management" },
  { key: 7, text: "Professional Driver", value: "Professional Driver" },
  { key: 8, text: "Professional/Technical", value: "Professional/Technical" },
  { key: 9, text: "Retail Service", value: "Retail Service" },
  { key: 10, text: "Retired", value: "Retired" },
  { key: 11, text: "Sales/Marketing", value: "Sales/Marketing" },
  { key: 12, text: "Self-Employed", value: "Self-Employed" },
  { key: 13, text: "Services/Creative", value: "Services/Creative" },
  {
    key: 14,
    text: "Skilled/Trade/Machine Operator/Laborer",
    value: "Skilled/Trade/Machine Operator/Laborer"
  },
  { key: 15, text: "Teacher/Educator", value: "Teacher/Educator" },
  {
    key: 16,
    text: "Upper Management/Executives",
    value: "Upper Management/Executives"
  },
  { key: 17, text: "Work from Home", value: "Work from Home" },
  { key: 18, text: "Other Occupation", value: "Other Occupation" }
];

export const suffixOptions = [
  { key: 0, text: "", value: null },
  { key: 1, text: "Jr.", value: "Jr." },
  { key: 2, text: "Sr.", value: "Sr." },
  { key: 3, text: "I", value: "I" },
  { key: 4, text: "II", value: "II" },
  { key: 5, text: "III", value: "III" }
];

export const maritalStatusOptions = [
  { key: 0, text: "Married", value: "Married" },
  { key: 1, text: "Divorced", value: "Divorced" },
  { key: 2, text: "Single", value: "Single" },
  { key: 3, text: "Separated", value: "Separated" }
];

export const businessOperatingStatuses = [
  { key: 0, text: "Going Concern", value: "Going Concern" },
  { key: 1, text: "Closed", value: "Closed" },
  { key: 2, text: "Bankruptcy-Liquidation", value: "Bankruptcy-Liquidation" },
  {
    key: 3,
    text: "Bankruptcy - Reorganization",
    value: "Bankruptcy - Reorganization"
  }
];

export const creditBureaus = [
  { key: "Ex", text: "Experian", value: "Experian" },
  { key: "Eq", text: "Equifax", value: "Equifax" },
  { key: "TU", text: "Transunion", value: "Transunion" }
];

export const fyeOptions = [
  { key: "12/31", text: "12/31", value: "12/31" },
  { key: "6/30", text: "6/30", value: "6/30" },
  { key: "9/30", text: "9/30", value: "9/30" },
  { key: "3/31", text: "3/31", value: "3/31" }
];

export const defaultLoanOfficerOptions = [
  { key: "default", text: "Loading Loan Officers", value: "" }
];
