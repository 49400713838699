import React from "react";
import PropTypes from "prop-types";
import { Label } from "@bafsllc/ui-shared";
import { TASK_STATUS_TAG_COLOR } from "../../../../constants/taskManagement";

function StatusCell({ value = "" }) {
  const color = TASK_STATUS_TAG_COLOR[value] || "gray";

  if (!value) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <Label
        message={{
          id: `TASK_STATUS_${value}`.toUpperCase()
        }}
        color={color}
      />
    </div>
  );
}

export default StatusCell;

StatusCell.propTypes = {
  value: PropTypes.string
};
