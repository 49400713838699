/* eslint-env browser */
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Segment } from "semantic-ui-react";
import {
  getClientDepartments,
  getTask,
  getTaskEntities,
  getTaskTypes,
  getTaskUsers
} from "../../actions/Tasks";
import useTasks from "../../services/Hooks/useTasks";
import DocTitle from "../../components/DocTitle";
import ActionsBar from "./ActionsBar";
// import LeftNav from "./LeftNav";
import TasksTable from "./TasksTable";
import ManageTaskSideSheet from "./ManageTaskSideSheet";
import Pagination from "./Pagination";

const TaskManagement = () => {
  const dispatch = useDispatch();
  // Redux
  const auth = useSelector(state => state.auth);
  const TaskMgmt = useSelector(state => state.TaskMgmt);
  // Auth
  const institutionUuid = auth.institutionUuid || null;
  // const institutionUuid = "institut-ions-0016-IBLS-TestBranch00";

  const task = TaskMgmt?.task || null;

  const {
    tasks,
    pageSize,
    pageNumber,
    count,
    lastPage,
    isFetching,
    addTask,
    updateTask,
    assignTask,
    updateFilters,
    updatePageNumber,
    updatePageSize,
    filters
  } = useTasks(
    { responsible_institution_uuid: institutionUuid },
    { limit: 10, page: 1 }
  );

  const openSideSheet = useCallback(() => {
    if (task?.uuid) {
      dispatch({
        type: "SET_TASK_SIDESHEET",
        sidesheetVisible: true,
        sidesheetEditMode: false,
        sidesheetTab: "taskDetails",
        task
      });
    }
  }, [dispatch, task]);

  useEffect(() => {
    openSideSheet();
  }, [openSideSheet]);

  useEffect(() => {
    dispatch(getTaskTypes());
    dispatch(getClientDepartments());
    dispatch(getTaskEntities(institutionUuid));
    dispatch(getTaskUsers(institutionUuid));
  }, [dispatch, institutionUuid]);

  return (
    <>
      <DocTitle title="Task Management - BLAST Portal">
        <h1 className="mb-6">Task Management</h1>
        <Container className="leftAlign" fluid textAlign="left">
          <div className="grid grid-cols-12 gap-4">
            {/* <LeftNav className="col-span-2 border border-neutral-300" /> */}
            <div
              className="col-span-12" // col-span-10
            >
              <ActionsBar
                className="mb-3"
                updateFilters={updateFilters}
                filters={filters}
              />

              <Segment.Group>
                <Segment>
                  <TasksTable
                    data={tasks}
                    onClickView={task => {
                      dispatch(getTask(task?.uuid));
                    }}
                    isFetching={isFetching}
                    showActionsCell
                    // showActionsMenu
                    // showSelectCell
                  />
                </Segment>

                {tasks.length > 0 && !isFetching && (
                  <Segment>
                    <Pagination
                      items={tasks}
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      lastPage={lastPage}
                      count={count}
                      onChangePageNumber={value => {
                        updatePageNumber(value);
                      }}
                      onChangePageSize={value => {
                        updatePageSize(value);
                      }}
                    />
                  </Segment>
                )}
              </Segment.Group>
            </div>
          </div>
        </Container>
      </DocTitle>
      <ManageTaskSideSheet
        addTask={addTask}
        updateTask={updateTask}
        assignTask={assignTask}
      />
    </>
  );
};

export default TaskManagement;
