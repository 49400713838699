import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu
} from "semantic-ui-react";
import FontAwesome from "react-fontawesome";
import { FormattedMessage } from "react-intl";
import { permCheck } from "../../../../services/Auth";
import { ButtonTW } from "@bafsllc/ui-shared";

// NOTE: can use toggleAllRowsSelected from args if
// it's needed to unselect all rows
function ActionsCell({ row, column }) {
  const task = row?.original;
  const updatePerms = permCheck([
    "admin"
    // TODO task mgmt perms
  ]);

  const onEdit = () => {
    column?.onClickEdit(task);
  };

  const onView = () => {
    column?.onClickView(task);
  };

  return (
    <div className="flex justify-center">
      {column?.showActionsMenu ? (
        <Dropdown
          icon={<FontAwesome name="ellipsis-h" />}
          floating
          direction="left"
          className="ui tiny basic icon button"
        >
          <DropdownMenu className="floating ActionsCell-menu" direction="right">
            <DropdownItem
              data-testid="edit-asset-row-dropdown-item"
              onClick={onView}
              // disabled={!updatePerms}
            >
              <FormattedMessage id="VIEW" />
            </DropdownItem>
            <Divider className="bg-neutral-200 !m-0" />
            <DropdownItem
              data-testid="edit-asset-row-dropdown-item"
              onClick={onEdit}
              // disabled={!updatePerms}
            >
              <FormattedMessage id="EDIT" />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <ButtonTW
          variant="secondary"
          size="sm"
          data-testid="view-task-row-btn"
          onClick={onView}
        >
          <FormattedMessage id="VIEW" />
        </ButtonTW>
      )}
    </div>
  );
}

export default ActionsCell;

ActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape()
  }),
  toggleAllRowsSelected: PropTypes.func,
  column: PropTypes.shape({
    onClickEdit: PropTypes.func,
    onClickBorrowerEditLock: PropTypes.func,
    showActionsMenu: PropTypes.bool
  })
};
