import React, { useMemo } from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Note: Naming conflicts & iterative refactoring required adding the
// suffix "TW" (tailwind) until this component replaces all Button components

export interface ButtonTWProps {
  children: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "sm" | "md" | "lg";
  type?: "button" | "submit";
  fontAwesomeIcon?: FontAwesomeIconProps;
  iconPosition?: "left" | "right";
  style?: React.CSSProperties;
  onClick?: () => void;
  loading?: boolean;
}

export function ButtonTW(props: ButtonTWProps) {
  const {
    children,
    className = "",
    variant = "primary",
    size = "md",
    type = "button",
    fontAwesomeIcon,
    iconPosition = "left",
    loading = false,
    ...rest
  } = props;

  const spinnerClassName = useMemo(() => {
    let result = "fa-spin";

    if (children) {
      if (iconPosition === "left") {
        result += " mr-2";
      }

      if (iconPosition === "right") {
        result += " ml-2";
      }
    }

    return result;
  }, [children, iconPosition]);

  // TODO: follow forwardRef and createELement (button/a/Link) as implemented in
  // libs/ui-shared/src/lib/fomantic/elements/button/button-base/button-base.tsx

  return (
    <button
      type={type}
      className={`btn btn-${size} btn-${variant} ${className} `}
      {...rest}
    >
      <span className="flex items-center">
        {!loading && fontAwesomeIcon?.icon && iconPosition === "left" && (
          <FontAwesomeIcon
            {...fontAwesomeIcon}
            className={children ? "mr-2" : ""}
            data-testid="ButtonTW-icon-left"
          />
        )}

        {loading && iconPosition === "left" && (
          <FontAwesomeIcon
            icon={faSpinner}
            className={spinnerClassName}
            data-testid={`ButtonTW-loading-icon-${iconPosition}`}
          />
        )}

        {children && <span>{children}</span>}

        {!loading && fontAwesomeIcon?.icon && iconPosition === "right" && (
          <FontAwesomeIcon
            {...fontAwesomeIcon}
            className={children ? "ml-2" : ""}
            data-testid="ButtonTW-icon-right"
          />
        )}

        {loading && iconPosition === "right" && (
          <FontAwesomeIcon
            icon={faSpinner}
            className={spinnerClassName}
            data-testid={`ButtonTW-loading-icon-${iconPosition}`}
          />
        )}
      </span>
    </button>
  );
}
