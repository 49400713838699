import blastEndpoints from "./BlastEndpoints";
import { relationshipTypes } from "../Constants/relationshipTypes";
/* eslint-env browser */

const { hostname } = window.location;
const trimmedHostname = hostname.split(".").slice(-2).join(".");
const isLocal = process.env.NODE_ENV === "development";
const baseUri = `https://api.${trimmedHostname}`;
const baseJsonServer = "http://localhost:3030";

const ApiEndpoints = {
  baseUri,
  baseJsonServer,
  isLocal,
  authBase: "/auth/v1",
  authUsers: "/users/",
  authLogin: "/login",

  // Tombstone user services
  users: "/auth/v1/users",
  user: "/auth/v1/users/{uuid}",

  // Tombstone user info services
  userInfo: "/auth/v1/user_info/{uuid}",
  userInfoAll: "/auth/v1/user_info",
  userInfoByInstitution: "/auth/v1/user_info?institution_uuid={instUuid}",
  userInfoByName:
    "/auth/v1/user_info?first_name={firstName}&last_name={lastName}",
  userInfoByUsername: "/auth/v1/user_info?username={username}",

  // Tombstone password reset services
  passwordReset: "/auth/v1/password_reset",
  passwordResetGet: "/auth/v1/password_reset/{uuid}",

  // CMS v2 API Endpoints
  spreadv2: "/cms/v2/spreads/{uuid}",
  spreadsv2: "/cms/v2/spreads",
  cmsSpreadsModelv2: "/cms/v2/financial_spreads_models/{uuid}",
  cmsSpreadsModelsv2: "/cms/v2/financial_spreads_models",
  spreadsPeriodDatav2: "/cms/v2/period_statements",
  spreadsPeriodDatumv2: "/cms/v2/period_statements/{periodUuid}",
  cmsCreditNarrativeByInstitution:
    "/cms/v2/credit_narrative_templates?institutionUuid={institutionUuid}&include=TemplateRevisions&filter=status=in(Archived,Production)",
  cmsInstitutionConfig: "/cms/v2/institution_config/",
  entityAnalyses: "/cms/v2/entity_analyses",
  entityAnalysesPut: "/cms/v2/entity_analyses/{uuid}",
  creditMemo: "/cms/v2/credit_memo",
  convertDocument: "/cms/v2/convert_document",

  // CMS-RS Endpoints
  financialAnalysisTemplate:
    "/cms-reports/spreads/{spreadUuid}/render-template?institutionUuid={institutionUuid}",
  financialAnalysisReport:
    "/cms-reports/spreads/{spreadUuid}/financial-analysis?institutionUuid={institutionUuid}&fileUuid={fileUuid}",
  mergeDocuments: "/cms-reports/merge-documents",

  // CMS API Endpoints - NEW
  spread: "/cms/v1/financialAnalysis/{uuid}",
  spreads: "/cms/v1/financialAnalysis",
  cmsSpreadsModel: "/cms/v1/spreads/models/{uuid}",
  cmsSpreadsModels: "/cms/v1/spreads/models",
  spreadsPeriodData: "/cms/v1/spreads/periodData/{entityUuid}",
  spreadsPeriodDatum: "/cms/v1/spreads/periodData/{entityUuid}/{periodUuid}",
  // CMS Credit Ratings API endpoints
  singleRisk:
    "/credit-ratings/v1/single-risk-scales?include=SingleRiskScaleItems&filter[status]=in(Production)",
  creditAnalysis:
    "/credit-ratings/v1/credit-analysis/single?include=SingleRiskScales.SingleRiskScaleItems&filter[{type}_uuid]=in({loanUUID})",
  creditAnalysisByUUID:
    "/credit-ratings/v1/credit-analysis/single/{uuid}?include=SingleRiskScales.SingleRiskScaleItems",
  singleRiskCreditAnalysisByEntityUUID:
    "/credit-ratings/v1/credit-analysis/single?include=SingleRiskScaleItems&filter[status]=in(Current)&filter[entity_uuid]=in({entityUUID})",
  singleRiskCreditAnalysisByLoanUUID:
    "/credit-ratings/v1/credit-analysis/single?include=SingleRiskScaleItems&filter[status]=in(Current)&filter[loan_uuid]=in({loanUUID})",
  // Financial Analysis API Endpoints - OLD
  // this is used within CreditManagement TableView component
  financialAnalysisPut: "/cms/v1/financialAnalysis/{uuid}",
  // Relationships API Endpoints
  relationshipsV1: "/relationships/v1/relationships",
  relationships: "/relationships/v2/relationships",
  relationship: "/relationships/v2/relationships/{uuid}",
  relationshipsPut: "/relationships/v2/relationships/{uuid}",
  relationshipsDelete: "/relationships/v2/relationships/{uuid}",
  relationshipsGetOwnersByParentUuid: `/relationships/v2/relationships?parent_uuid={parentUuid}&rel_type=${relationshipTypes.OWNER}`,
  relationshipsGetOwnersByChildUuid: `/relationships/v2/relationships?child_uuid={childUuid}&rel_type=${relationshipTypes.OWNER}`,
  relationshipsGetCoborrowersByParentUuid: `/relationships/v2/relationships?parent_uuid={parentUuid}&rel_type=${relationshipTypes.BORROWER}&primary_borrower=false`,
  relationshipsGetGuarantorsByParentUuid: `/relationships/v2/relationships?parent_uuid={parentUuid}&rel_type=${relationshipTypes.GUARANTOR}`,

  // Relationship Review API Endpoints
  relationshipReviewGetAllForInst:
    "/relationships/v1/relationshipReview?institutionUuid={institutionUuid}",
  relationshipReviewGetAllForEntity:
    "/relationships/v1/relationshipReview?entityUuid={entityUuid}",
  relationshipReviewIndividual: "/relationships/v1/relationshipReview/{uuid}",
  relationshipReviewPost: "/relationships/v1/relationshipReview",
  // Relationship Review Model

  // this and Loan Review Model are deliberately the same, with different names in case one changes
  relationshipReviewModel: "/loans/v1/loan_review_model/{uuid}",

  // Loan Review Model API Endpoints
  loanReviewModel: "/loans/v1/loan_review_model/{uuid}",
  loanReviewModels: "/loans/v1/loan_review_model",

  // Opt Out calls
  optOut: "/loans/v1/optOut",
  optOutGet: "/loans/v1/optOut/{parent_uuid}",

  // New LOR API Endpoints
  // Institutions API Endpoints
  institutions: "/institutions/v1/",
  institution: "/institutions/v1/{uuid}",
  institutionbyId: "/institutions/v1/?id={id}",

  // Participation Servicers
  participationServicers: "/institutions/v1/participation_servicers",
  participationServicer: "/institutions/v1/participation_servicers/{uuid}",

  // Participation Investors
  participationInvestors: "/loans/v1/participation_investors",
  participationInvestor: "/loans/v1/{uuid}/participation_investor",
  participationInvestorUpdate:
    "/loans/v1/{parent_loan_uuid}/participation_investor/{participation_loan_uuid}",

  // Snapshots
  getSnapshots: "/loans/v1/",
  // Nightly Process
  nightlyProcess: "/loans/v1/scripted_actions/all?page_size=99999",
  // Nightly Bills
  nightlyBills: "/loans/v1/scripted_actions/bill_updates?page_size=99999",
  // Undo Nightly Accruals
  undoNightlyAccruals: "/loans/v1/scripted_actions/undo_accrued_interest",

  // Institution Documents - Document Tracking
  institutionDocuments: "/documents/v1/metadata",
  institutionDocument: "/documents/v1/metadata/{uuid}",
  institutionDocumentsGetAllByInstitutionUuid:
    "/documents/v1/institutionDocumentDefaults?institutionUuid={uuid}",
  institutionDocumentGetAllByScheduleUuid:
    "/documents/v1/metadata?scheduleUuid={uuid}",
  // Schedules API Endpoints - Document Tracking
  schedules: "/documents/v1/schedules",
  schedule: "/documents/v1/schedules/{uuid}",
  schedulesGetAllByInstitutionDocumentUuid:
    "/documents/v1/schedules?documentMetadataUuid={uuid}",
  schedulesGetAllByCurrentState:
    "/documents/v1/schedules?currentState={currentState}",
  schedulesGetAll: "/documents/v1/schedules?parentUuid={parentUuid}",
  // Ticklers API Endpoints  - Document Tracking
  ticklers: "/documents/v1/tickler",
  tickler: "/documents/v1/tickler/{uuid}",
  ticklersGetAllActiveForInstitution:
    "/documents/v1/tickler/?state=Active&institutionUuid={institutionUuid}",
  ticklersGetAllForInstitution:
    "/documents/v1/tickler/?institutionUuid={institutionUuid}",
  ticklersGetAllActive:
    "/documents/v1/tickler?state=Active&parentUuid={parentUuid}",
  ticklersGetAll: "/documents/v1/tickler?parentUuid={parentUuid}",
  ticklersGetAllBySchedule: "/documents/v1/tickler?scheduleUuid={uuid}",
  exceptionsCount: "/documents/v1/tickler/count",
  // Messages v2 - deprecated, use v3 ApiLib
  messagePost: "/messages/v2/",
  messagesGetAllByParentUuid: "/messages/v2/?parentUuid={parentUuid}",
  messagesGetAllByUserUuid: "/messages/v2/?userUuid={userUuid}",
  // Messages v3
  messagesV3: "/messages/v3/messages",

  // Entities API Endpoints
  lorEntityDetails: "/lorEntityDetails",
  entities: "/entities/v2/entities",
  entitiesList: "/entities/v2/entities/uuid_list",
  entitiesGetAllByTin: "/entities/v2/entities?tin={tin}",
  entitiesGetAllByInstitutionUuid:
    "/entities/v2/entities?institution_uuid={institutionUuid}",
  cmsEntity: "/entities/v2/entities/{uuid}", // TODO: Anything using this should use 'entity'
  entity: "/entities/v2/entities/{uuid}",
  entityRelationships: "/entities/v2/relationships/{entity_uuid}/{rel_type}",
  entityLookup: "/entities/v2/entities?lookup={lookupString}",
  entityLookupAndFilterByInstUuid:
    "/entities/v2/entities?lookup={lookupString}&institution_uuid={institutionUuid}",
  // rel_type can be: "owners", "affiliates", "officers", "co-borrowers", "guarantors", "others"
  entityRelationshipsOwners: `/entities/v2/relationships/{entity_uuid}/${relationshipTypes.OWNER}`,
  entityRelationshipsAffiliates: `/entities/v2/relationships/{entity_uuid}/${relationshipTypes.AFFILIATE}`,
  entityRelationshipsOfficers: `/entities/v2/relationships/{entity_uuid}/${relationshipTypes.OFFICER}`,
  entityRelationshipsOthers: `/entities/v2/relationships/{entity_uuid}/${relationshipTypes.OTHER_CAPACITIES}`,
  entityRelationshipsAuthorizedSigner:
    "/entities/v2/relationships/{entity_uuid}/Signer",
  entityRelationshipsCoborrowers: `/entities/v2/relationships/{entity_uuid}/${relationshipTypes.BORROWER}?primary_borrower=false`,
  entityRelationshipsGuarantors: `/entities/v2/relationships/{entity_uuid}/${relationshipTypes.GUARANTOR}`,
  entityRealEstateAsset: "/entities/v2/assets_real_estate/{uuid}",
  entityPersonalPropertyAsset: "/entities/v2/assets_personal_property/{uuid}",
  entityAccountAsset: "/entities/v2/assets_accounts/{uuid}",
  entityRealEstateByAssetUuid:
    "/entities/v2/assets_real_estate?asset_uuid={uuid}",
  entityPersonalPropertyByAssetUuid:
    "/entities/v2/assets_personal_property?asset_uuid={uuid}",
  entityAccountByAssetUuid: "/entities/v2/assets_accounts?asset_uuid={uuid}",
  addEntityRealEstateAsset: "/entities/v2/assets_real_estate",
  addEntityPersonalPropertyAsset: "/entities/v2/assets_personal_property",
  addEntityAccountAsset: "/entities/v2/assets_accounts",
  entityIndebtedness: "/entities/v2/entities/{entityUuid}/debt",

  entitiesGetAllBpLinks:
    "/entities/v2/borrower_portal_links?bp_organization_uuid={bp_organization_uuid}",
  entitiesGetAllBPUsers:
    "/entities/v2/borrower_portal_users?lookup={lookupString}&institution_uuid={institutionUuid}",
  entitiesBpUsersValidateEmail:
    "/entities/v2/borrower_portal_users/validate_email?email={email}",
  entitiesBpUsersUser:
    "/entities/v2/borrower_portal_users/user?institution_uuid={institutionUuid}&bp_organization_uuid={bpOrganizationUuid}",
  entitiesBpUsersSendSignupEmail: "/entities/v2/entities/{uuid}/signup",
  entityBpOrgLink: "/entities/v2/entities/{entityUuid}/borrower_portal_link",

  // Borrow Portal Access Controls (bp)
  bpAccessControlsGetByRecordUuid: "/loans/v1/bp_access_controls/{recordUuid}",

  // Credit Requests API Endpoints (formerly Loan Requests)
  creditRequests: "/loans/v1/loan_requests",
  creditRequest: "/loans/v1/loan_requests/{uuid}",
  creditRequestsGetAllByEntityUuid:
    "/loans/v1/loan_requests?entity_uuid={entityUuid}",
  creditRequestsGetAllByLoanAppUuid:
    "/loans/v1/loan_requests?loan_app_uuid={loanAppUuid}",
  // Loans API Endpoints
  loans: "/loans/v1/",
  loansRelationships: "/loans/v1/relationships/{uuid}",
  loan: "/loans/v1/{uuid}",
  loansPut: "/loans/v1/{uuid}",
  loansGetAllByEntityUuid: "/loans/v1/?entity_uuid={entityUuid}",
  loansGetAllByEntityAndInstUuid:
    "/loans/v1/?entity_uuid={entityUuid}&institution_uuid={institutionUuid}",
  loansGetAllByLoanAppUuid: "/loans/v1/?loan_app_uuid={loan_app_uuid}",
  loansGetAllByCount: "/loans/v1/loans/count",
  loanBill: "/loans/v1/{loan_uuid}/bills/{bill_uuid}",
  loanBills: "/loans/v1/{loan_uuid}/bills",
  loanFinalize: "/loans/v1/{loan_uuid}/finalize",
  // Loans related details
  loanRelatedDetails: "/loans/v1/{loan_uuid}/related_details",
  loanRelatedDetail: "/loans/v1/{loan_uuid}/related_details/{uuid}",
  // PPP Loans Endpoints
  loansPPP: "/loans/v1/loan_ppp_data/",
  loanPPP: "/loans/v1/loan_ppp_data/{uuid}",

  // filter by institution_uuid, officer_uuid, loan_class, status, and date range
  // date range example: date_type=contract_date&start_date=2018-12-01&end_date=2018-12-02
  loansGetCountbyClass: "/loans/v1/loans/count?loan_class={classType}",
  loansGetCountbyIndustry: "/loans/v1/loans/count/industry_category",
  loansGetFiltered:
    "/loans/v1/loans/count?institution_uuid={institutionUuid}&date_column={columnType}&start_date={startDate}&end_date={endDate}",

  // Loan Applications
  loan_apps: "/loans/v1/loan_apps",
  loan_apps_create_default:
    "/loans/v1/loan_apps/create_with_default_loan_and_schedules",
  loan_apps_create_blank:
    "/loans/v1/loan_apps/{loan_app_uuid}/add_new_blank_loan",
  loan_app: "/loans/v1/loan_apps/{uuid}",
  loan_apps_total_count: "/loans/v1/loan_apps/count_total_app",

  // Settlement Sheet Endpoints
  settlementSheet: "/loans/v1/loan_apps/{loan_uuid}/settlement_sheet_items",
  settlementSheetById:
    "/loans/v1/loan_apps/{loan_uuid}/settlement_sheet_items/{item_uuid}",

  // 5300 Code
  loan5300Codes: "/loans/v1/code_5300",
  loan5300Code: "/loans/v1/code_5300/{uuid}",
  loan5300CodePost: "/loans/v1/code_5300/uuid_list",
  // 5300 Code Relationship
  loanCodeRelationships: "/loans/v1/code_5300_relationships",
  loanCodeRelationship: "/loans/v1/code_5300_relationships/{uuid}",
  // Loan Payment Breakdown/details
  loanPaymentBreakdowns:
    "/loans/v1/{loan_uuid}/transactions/{pmt_transaction_uuid}/details",
  loanPaymentBreakdown:
    "/loans/v1/{loan_uuid}/transactions/{pmt_transaction_uuid}/details/{uuid}",
  // Loan Payoff
  loanPayoff: "/loans/v1/loans/{uuid}/payoff?effective_date={effectiveDate}",
  // Loan Payment Schedules
  loanPaymentSchedules: "/loans/v1/{loan_uuid}/schedules",
  loanPaymentSchedule: "/loans/v1/{loan_uuid}/schedules/{uuid}",
  // Loan Payment Schedules
  loanPremiumDiscounts: "/loans/v1/loan_premium_discount",
  loanPremiumDiscount: "/loans/v1/loan_premium_discount/{uuid}",
  // Loan Payment Schedules
  loanPremiumDiscountHistories: "/loans/v1/loan_premium_discount_history",
  loanPremiumDiscountHistory: "/loans/v1/loan_premium_discount_history/{uuid}",
  // Loan Interest Schedules
  loanInterestSchedules: "/loans/v1/{loan_uuid}/interest_schedules",
  loanInterestSchedule: "/loans/v1/{loan_uuid}/interest_schedules/{uuid}",
  // Loan Transaction History
  loanTransactionHistory: "/loans/v1/{loan_uuid}/transactions/{uuid}",
  loanTransactionHistories: "/loans/v1/{loan_uuid}/transactions",
  previewLoanTransactionHistory: "/loans/v1/{loan_uuid}/transactions_v2",
  loanTransactionHistoryReversal:
    "/loans/v1/{loan_uuid}/transactions/{uuid}/reversal",
  loanTransactionHistoryReversalV2:
    "/loans/v1/{loan_uuid}/transactions_v2/{uuid}/reversal",
  loanPostDirectTransaction: "/loans/v1/{loan_uuid}/transactions_direct_post",
  // Assets API Endpoints
  assets: "/entities/v2/assets",
  asset: "/entities/v2/assets/{uuid}",
  assetsGetAllByEntityUuid: "/entities/v2/assets?entity_uuid={entityUuid}",

  // Additional Fees API Endpoints - Not yet implemented
  fees: "/loans/v1/{loan_uuid}/fees-not-yet-implemented",
  fee: "/loans/v1/{loan_uuid}/fees-not-yet-implemented/{fee_uuid}",
  additionalFees: "/loans/v1/additional_fees",
  additionalFee: "/loans/v1/additional_fees/{feeUuid}",

  // Additional Gov't Guaranteed Fees API Endpoints - Not yet implemented
  govGuaranteeFees:
    "/loans/v1/{loan_uuid}/gov-guarantee-fees-not-yet-implemented",
  govGuaranteeFee:
    "/loans/v1/{loan_uuid}/gov-guarantee-fees-not-yet-implemented/{gov_guarantee_fee_uuid}",

  // Loan Account History Logs - Not yet implemented
  accountHistoryLogs: "/loans/v1/{loan_uuid}/account-logs",
  accountHistoryLog: "/loans/v1/{loan_uuid}/account-logs/{account_log_uuid}",

  // Collateral
  collaterals: "/loans/v1/collateral",
  collateral: "/loans/v1/collateral/{uuid}",
  collateralGetAllByEntityUuid: "/loans/v1/collateral?entity_uuid={entityUuid}",
  collateralGetAllByLoanUuid: "/loans/v1/collateral?loan_uuid={loanUuid}",
  // Documents API Endpoints - OLD (Still in Use)
  docsApprove: "/docs/mgmt/approve/{uuid}/",
  docsEdit: "/docs/mgmt/edit/{uuid}/",
  docsReject: "/docs/mgmt/reject/{uuid}/",
  docsReset: "/docs/mgmt/reset/{uuid}/",
  docsLoadPending: "/docs/mgmt/pending/",
  // Documents API Endpoints - NEW (Partially in Use)
  docsGetUris: "/documents/v1/",

  // Objects API Endpoints (New s3 Storage and document metadata)
  /* Object Metadata works differently from other APIs to avoid sending files unnecessarily.
  Files are uploaded/downloaded w/ objectUploads/objectDownloads. Uploading a file creates metadata
  Metadata uses the uuid of the file and should be PUT to and GET from, no POSTING to Metadata.

  */
  objectUploads: "/objects/v1/object",
  objectUploadsWithInstitution:
    "/objects/v1/object?institutionUuid={institutionUuid}",
  objectDownloads: "/objects/v1/object/{uuid}",
  objectCommonDownloads: "/objects/v1/object_common/{uuid}",
  objectDownloadsWithInstitution:
    "/objects/v1/object/{uuid}?institutionUuid={institutionUuid}",
  objectMetadata: "/objects/v1/object/{uuid}/metadata",
  objectMetadataGetAll: "/objects/v1/object",
  objectMetadataGetAllByInstitutionUuid:
    "/objects/v1/object?institutionUuid={institutionUuid}",
  objectMetadataGetAllByEntityUuid:
    "/objects/v1/object?entityUuid={entityUuid}",
  objectMetadataGetAllByDocumentCategory:
    "/objects/v1/object?docCategory={docCategory}&institutionUuid={institutionUuid}",
  objectMetadataGetAllByParentUuid:
    "/objects/v1/object?parentUuid={parentUuid}&institutionUuid={institutionUuid}",
  objectV4Metadata: "/objects/v4/metadata",
  objectUploadsv2: "/objects/v2/object",

  // Credit Memo API Endpoints
  creditMemoModel: "/cms/v1/creditMemo/models/{uuid}",
  creditMemoGetSingle: "/cms/v1/creditMemo/data/{memoUuid}",
  creditMemoGetAllByInstitution:
    "/cms/v1/creditMemo/data?institutionUuid={institutionUuid}",
  creditMemoGetAllByEntity: "/cms/v1/creditMemo/data?entityUuid={entityUuid}",
  creditMemoPost: "/cms/v1/creditMemo/data",
  // Credit Risk Review API Endpoints
  creditRiskReviewGetAllForInst:
    "/loans/v1/creditRiskReview?institutionUuid={institutionUuid}",
  creditRiskReviewGetAllForEntity:
    "/loans/v1/creditRiskReview?entityUuid={entityUuid}",
  creditRiskReviewGetAllForLoan:
    "/loans/v1/creditRiskReview?loanUuid={loanUuid}",
  creditRiskReviewIndividual: "/loans/v1/creditRiskReview/{uuid}",
  creditRiskReviewPost: "/loans/v1/creditRiskReview",

  // -= Phoenix =- //
  // Transaction History
  phxTransactionHistory: "/loans/v1/transaction_history?acct_no={acctNumber}",

  // Render
  renderCreditMemo: "/render/v1/creditMemo/{memoUuid}",
  renderCreditRiskReview: "/render/v1/creditRiskReview/{uuid}",
  renderRelationshipReview:
    "/render/v1/relationshipReview/{relationshipReviewUuid}",
  renderFinancialAnalysis: "/render/v1/financialAnalysis/{uuid}",

  // Auto Decision API Endpoints
  /* POST to this endpoint with business_uuid, personal_uuid, and loan_uuid
   * to initiate the autodecision process.
   */
  decisionHandlerEndpoint: "/decision_handler/v1/decision",

  // CSI
  csiCommercialTransaction: "/csi/v1/commercial/transaction/{uuid}",
  csiCommercialTransactions: "/csi/v1/commercial/transactions",

  // Notifications
  notifications: "/notifications/v2/notifications",
  subscriptions: "/notifications/v2/subscriptions",
  internalNotifications:
    "/notifications/v2/notifications/internal_notification",

  // TASK MANAGEMENT
  tasks: "/tasks/v1/",
  task: "/tasks/v1/{uuid}",
  taskAssign: "/tasks/v1/{uuid}/assign",
  taskNotes: "/tasks/v1/{uuid}/notes",
  taskEvents: "/tasks/v1/{uuid}/events",
  taskTypes: "/tasks/v1/task-types",
  clientDepartments: "/tasks/v1/client-departments",
  clientDepartment: "/tasks/v1/client-departments/{uuid}",
  institutionClientDepartments:
    "/tasks/v1/client-departments/institutions/{institutionUuid}",

  // Colson 1502
  colson1502: "/aws/1502-reports",

  ...blastEndpoints
};

export default ApiEndpoints;
