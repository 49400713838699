import React from "react";
import Select, { GroupBase, Props } from "react-select";
import { FormLabel } from "../FormLabel";
import { SelectMenuProps } from "./types";
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromArray,
  reactSelectOptionsFromHashMap
} from "./utils";
import "./style.css";
import { kebabCase } from "lodash";
import { CommonMessages } from "@blast-client/constants";

const { PLEASE_MAKE_SELECTION } = CommonMessages;

/**
 * react-select Wrapper
 * Documentation: https://react-select.com/props
 */

export function SelectMenu<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  className = "",
  testId,
  labelText = "",
  labelClassName = "",
  dataArray,
  dataLabelKey,
  dataValueKey,
  dataMap,
  disabledOptionsMap = {},
  value,
  ...props
}: Props<Option, IsMulti, Group> & SelectMenuProps) {
  const getByLabelTextId = kebabCase(labelText).concat("-field");
  let selectOptions;
  let option;

  if (dataArray && dataLabelKey && dataValueKey) {
    selectOptions = reactSelectOptionsFromArray(
      dataArray,
      dataLabelKey,
      dataValueKey,
      disabledOptionsMap
    );
  }

  if (dataMap) {
    selectOptions = reactSelectOptionsFromHashMap(dataMap, disabledOptionsMap);
  }

  if (selectOptions) {
    if (props?.isMulti) {
      option = reactSelectDefaultValues(value, selectOptions);
    } else {
      option = reactSelectDefaultValue(value, selectOptions);
    }
  }

  return (
    <div className={className} data-testid={testId}>
      {labelText && (
        <FormLabel
          labelText={labelText}
          className={labelClassName}
          required={props?.required}
        />
      )}

      <Select
        {...props}
        inputId={getByLabelTextId}
        options={selectOptions || props?.options}
        // @ts-ignore
        isOptionDisabled={option => option.isDisabled}
        placeholder={props?.placeholder || PLEASE_MAKE_SELECTION}
        value={option}
        menuPlacement={props?.menuPlacement || "auto"}
        className="react-select-container"
        classNamePrefix="react-select"
        classNames={{
          ...{
            control: () => "!border-neutral-300",
            option: () => "!text-sm",
            placeholder: () => "!text-sm"
          },
          ...props.classNames
        }}
      />
    </div>
  );
}
